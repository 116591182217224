import React, { useEffect, useState, useRef, HTMLAttributes } from 'react';
import _ from 'lodash';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
    Theme, useTheme, Typography, Button, Box, Menu,
    MenuItem, IconButton, InputBase, LinearProgress
} from '@material-ui/core';
import {
    ChevronRight, AddCircle, Check, ArrowBackIos, Search, Close
} from '@material-ui/icons';


type TypeHierarchicalSelectorMenuProps = {
    delegate: any;
    selected: any[];
    setSelected: React.Dispatch<React.SetStateAction<any[]>>;
    transformSelection?: (path: any[], selection: any) => any;
    loading: React.MutableRefObject<boolean>;
    label?: string;
    isMultiSelect?: boolean;
    leafOnly?: boolean;
    hierarchical?: boolean;
} & HTMLAttributes<React.Component>

function HierarchicalSelectorMenu({
    delegate,
    selected,
    setSelected,
    transformSelection,
    loading,
    label,
    isMultiSelect,
    leafOnly,
    hierarchical
  }: TypeHierarchicalSelectorMenuProps) {

    const theme = useTheme();
    const style = styles(theme);

    const [anchor, setAnchor] = useState<SVGSVGElement | null>(null);
    const [selectedPath, setSelectedPath] = useState<any[]>([]);
    const [currentLevel, setCurrentLevel] = useState("");
    const [options, setOptions] = useState<any[]>([]);
    const iconRef = useRef<SVGSVGElement>(null);

    const [searchOpen, setSearchOpen] = useState(false);
    const [searchVal, setSearchVal] = useState('');

    const close = () => {
        setAnchor(null);
        setSelectedPath([]);
        setSearchOpen(false);
        setSearchVal('');
        setOptions([]);
    }

    const fetch = () => {
        setOptions([]);
        (async () => {
            setOptions(_.isFunction(delegate) ? await delegate(selectedPath, selected, close) : []);
        })();
    }

    const handleClick = (event: any) => {
        fetch();
        setAnchor(iconRef.current);
    };

    const handleDrillDown = (event: any, value: any) => {
        event.stopPropagation();
        if (_.isNil(value)) return;
        const tempArray = _.cloneDeep(selectedPath);
        tempArray.push(value);
        setSelectedPath(tempArray);
    };

    const isSelected = (option: any) => {
        const index = _.findIndex(selected, entry => {
            return entry.value === option.value;
        });

        const preSelectedIdx = _.findIndex(selected, item => item.value === option.value);

        return ((index !== -1) || (preSelectedIdx !== -1));
    };

    const handleSelect = (event: any, selection: any) => {
        event.stopPropagation();
        if (_.isNil(selection)) return;

        if (leafOnly && selection.chevron === true) {
            handleDrillDown(event, selection);
            return;
        }
        
        const tempArray = _.clone(selected);

        // deselect the selection if it was already selected
        if (isSelected(selection)) {
            const index = _.findIndex(selected, entry => {
                return entry.value === selection.value;
            });
            tempArray.splice(index, 1);
        } else {
            if (typeof transformSelection === 'function') {
                tempArray.push(transformSelection(selectedPath, selection));
            } else {
                tempArray.push(selection);
            }
        }

        setSelected(tempArray);
    };

    const handleBack = (event: any) => {
        const tempArray = _.cloneDeep(selectedPath);
        tempArray.pop();
        setSelectedPath(tempArray);
    };

    // if user has typed input, first click clears input, second click closes search
    const handleCloseSearch = () => {
        if (searchVal === '') {
            setSearchOpen(false);
        }
        setSearchVal('');
    }

    useEffect(() => {
        fetch();

        if (selectedPath.length > 0) {
            setCurrentLevel(_.get(_.last(selectedPath as any[]), 'display', ''));
        } else {
            setCurrentLevel('');
        }
        
    }, [selectedPath]);

    useEffect(() => {
        if (!isMultiSelect && selected.length > 0) {
            close();
        }    
    }, [selected]);


    const renderChevron = (option: any) => {
        const disabled = (hierarchical && isSelected(option)) ? true : false;
        return (
            <ChevronRight
                className={disabled ? style.chevronDisabled : style.chevron}
                onClick={disabled ? () => void 0 : (event) => handleDrillDown(event, option)}
            />
        );
    }

    const renderMenuItems = (options: any[]) => {
        if (options.length === 0) {
            return (
                <MenuItem
                    key='empty-menu-list-comp' 
                    value={'no-items'}
                    disabled
                >
                    <span className={style.grow}>
                        No Items...
                    </span>
                </MenuItem>
            )
        }
        return (_.map(
            // if there is a search value, filter options
            (searchVal.length > 0) ? 
                options.filter((o: any) => {
                    let display = false;
                    let id = false;
                    if (typeof o.display === 'string') {
                        display = o.display.toLowerCase().includes(searchVal.toLowerCase());
                    }
                    if (typeof o.id === 'string') {
                        id = o.id.toLowerCase().includes(searchVal.toLowerCase());
                    }
                    return display || id;
                })
                :
                options,
            // the map function
            (option, index) => (
                <MenuItem
                    key={_.get(option, 'value', _.get(option, 'key', index))} 
                    value={option.value}
                    onClick={(event) => handleSelect(event, option)}
                >
                    {isMultiSelect && 
                    <div className={style.CheckSpace}> 
                        {isSelected(option) && <Check className={style.Check}/>}
                    </div>}
                    <div className={style.itemNameId}>
                        <Typography color="secondary">
                            {option.display}
                        </Typography>
                        {hierarchical && 
                            <Typography 
                                color='secondary'
                                variant='subtitle2'
                                className={style.optionId}
                            >
                                {option.value}
                            </Typography>
                        }
                    </div>
                    {option.chevron && renderChevron(option)}
                </MenuItem>
            ))
        );
    }

    return (
        <div className={style.root}>
            {label && <Typography color="secondary">{label}</Typography>}
            <IconButton aria-haspopup="true" onClick={handleClick}>
                <AddCircle ref={iconRef} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={close}
                classes={{ list: style.menu }}
                
            >
                <Box
                    className={style.Header}
                    style={{ justifyContent: searchOpen ? 'center' : 'space-between' }}
                >
                    {searchOpen ?
                    <div className={style.search}>
                        <div className={style.searchIcon}>
                            <Search color='inherit'/>
                        </div>
                        <InputBase
                            autoFocus
                            placeholder="Search…"
                            classes={{
                                root: style.inputRoot,
                                input: style.inputInput,
                            }}
                            value={searchVal}
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={e => e.stopPropagation()}
                            onChange={(e) => {
                                e.stopPropagation();
                                setSearchVal(e.target.value);
                            }}
                        />
                        <div className={style.closeIcon} onClick={handleCloseSearch}>
                            <Close />
                        </div>
                    </div>
                    :
                    <>
                    <div className={style.headerLeft}>
                        {selectedPath.length > 0 && 
                            <Button 
                                size='small'
                                className={style.BackButton}
                                onClick={handleBack}
                            >
                                <ArrowBackIos fontSize='small' className={style.backIcon}/>
                                <Typography 
                                    color="secondary"
                                    variant='body1'
                                    className={style.white}
                                >
                                    {currentLevel}
                                </Typography>
                            </Button>
                        }
                    </div>
                    <Button
                        size='small'
                        onClick={() => setSearchOpen(true)}
                        className={style.openSearchButton}
                    >
                        <Search />
                    </Button>
                    </>
                }
                </Box>
                {loading.current && <LinearProgress />}
                {renderMenuItems(options)}
            </Menu>
        </div>
    );
}


/** MUI STYLE */
const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    menu: {
        padding: 0,
    },
    Header: {
        backgroundColor: theme.palette.primary.main,
        minWidth: '300px',
        height: '50px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerLeft: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
    },
    BackButton: {
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: 16,
        textTransform: 'none',
        textOverflow: 'ellipsis',
    },
    backIcon: {
        paddingLeft: 7,
    },
    openSearchButton: {
        color: theme.palette.common.white,
        height: '100%'
    },
    footer: {
        display: 'flex',
        flexShrink: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '4px',
        border: '1px solid white'
    },
    itemNameId: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    optionId: {
        fontSize: 13
    },
    grow: {
        flexGrow: 1
    },
    CheckSpace: {
        minWidth: "35px",
        minHeight: "40px"
    },
    Check: {
        marginTop: "7px"
    },
    chevron: {
        padding: 10,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
        transition: 'background-color .2s'
    },
    chevronDisabled: {
        padding: 10,
        borderRadius: '50%',
        color: 'lightgrey'
    },
    Level: {
        color: theme.palette.common.white,
        textAlign: "center",
        marginTop: "6px"
    },
    white: {
        color: 'white'
    },

    // search bar
    search: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'white',
        margin: '5px 6px 5px 6px',
        height: 30,
    },
    searchIcon: {
        color: '#455A64',
        paddingLeft: 4,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        paddingRight: 4
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        color: '#455A64',
        marginLeft: 4,
        transition: theme.transitions.create('width'),
        width: '100%',
    },

}));

export default HierarchicalSelectorMenu;