/********************************************************
 * File: Tooltip.Wrapper.tsx
 * Project: @liquid-mc/ui
 * File Created: 08-21-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 * 
 * Copyright © 2021 Liquid Analytics
*********************************************************/


import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { TypePropsTooltipWrapper } from '../../typings/component.types';

const TooltipWrapper: React.FC<TypePropsTooltipWrapper> = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const { closeTip, ...restOfProps } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (props.closeTip) {
            //console.log(`[PerformTooltip.Wrapper] Closing Tooltip...${open}`);
            setTimeout(() => {
                setOpen(false);
            }, 100);
        }
    }, [props.closeTip]);

    return (
        // spread out all the props to tooltip including children
        <StyledTooltip ref={ref} id={props.id} open={open} onClose={handleClose} onOpen={handleOpen} arrow {...restOfProps} />
    )
});

const StyledTooltip = withStyles({
    tooltip: {
        fontSize: '12px'
    }
})(Tooltip);

/** Export Module */
export default TooltipWrapper;