import React, { useState, useRef, useEffect } from 'react';
import Cookie, {parse, serialize} from 'cookie';
import clsx from 'clsx';
import {
  Theme,
  Container,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

// firebase
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithRedirect, onAuthStateChanged, getRedirectResult, signInWithCustomToken } from 'firebase/auth';

// Assets
import LiquidUrl, {
  ReactComponent as LiquidLogo,
} from "../../assets/Images/LA-Logo-2018-ColorDrop.svg";
import LiquidLogoText from "../../assets/Images/Liquid-Analytics-Logo-2018.png";
import GoogleAuthLogo from "../../assets/GoogleAuth/btn_google_signin_dark_normal_web.png";
import LiquidAuthLogo from "../../assets/Images/btn_ld_signin_dark_normal_web.png";

type LoginPageProps = {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginPage = (props: LoginPageProps) => {
  const [firebaseAuthCompleted, setFirebaseAuthCompleted] = useState(false);
  const [unknownUser, setUnknownUser] = useState(false);
  const isLoggingIn = useRef(false);

  const db = getFirestore();
  const auth = getAuth();
  

  /////////////////////
  // METHODS
  /////////////////////
  const triggerLogin = async () => {
    isLoggingIn.current = true;
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    signInWithRedirect(auth, provider);
  }

  const triggerLiquidLogin = async() => {
	console.log("Triggering Liquid Login");
	var loginPage = window.location.href;
	if (loginPage.indexOf("?")>0)
		loginPage = loginPage.substring(0, loginPage.indexOf("?")-1)
	loginPage = loginPage.replace("/", "%2F");
	window.location.replace('https://oauth2-qa.liquidanalytics.com/authorize?redirect_uri='+loginPage+'&client_id=LiquidDecisions&response_type=code');
  }

  const checkLiquidLogin = async() => {
	if (!firebaseAuthCompleted) {
		var loginResults = window.location.href;
		var sourceURL = loginResults
		if (loginResults.indexOf("?")>-1) {
			loginResults = loginResults.substring(loginResults.indexOf("?")+1)
			var liquidToken = undefined
			if (loginResults.indexOf("access_token")!=-1)
				liquidToken = loginResults.substring(loginResults.indexOf("access_token=")+13)
			var liquidCode = undefined
			if (loginResults.indexOf("code=")>-1) {
				liquidCode = loginResults.substring(loginResults.indexOf("code=")+5)
				if (liquidCode.indexOf("&")>-1)
					liquidCode = liquidCode.substring(0, liquidCode.indexOf("&"))
			}
			if (liquidCode!=undefined || liquidToken!=null)
			{
        isLoggingIn.current = true;
				const requestOptions = {
					method: 'POST',
					mode: 'cors',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ code: liquidCode, token: liquidToken })
				};
				fetch('https://us-central1-ascend2021-323117.cloudfunctions.net/ldAuthenticate', requestOptions)
				.then(response => {
          isLoggingIn.current = false;
					if (!response.ok) {
            //window.location.replace(loginResults.substring(0, loginResults.indexOf("?")))
            setUnknownUser(true);
						return;
          }

					response.json() .then(jsonData => {
						signInWithCustomToken(auth, jsonData.token).then((userCredential) => {
							window.location.replace(sourceURL.substring(0, sourceURL.indexOf("?")))
						});
					});
				})
				.catch( function(error) {
          isLoggingIn.current = false;
					//window.location.replace(loginResults.substring(0, loginResults.indexOf("?"))
					setUnknownUser(true);
					return;
				});
			}
		}
	}
  }

  /////////////////////
  // STYLE
  /////////////////////
  const style = loginPageStyle();

  /////////////////////
  // HOOKS
  /////////////////////

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      
      if (user) {
        // User is signed in.
        //console.log("Firebase Authentication Succeeded.");
        // check this user is in our database
        const email = auth.currentUser?.email;
        if (email == null) return;
	console.log(JSON.stringify(auth.currentUser));
        getDoc(doc(db, `User/${email}`)).then(userSnap => {
          setFirebaseAuthCompleted(true);
          if (userSnap.exists()) props.setIsLoggedIn(true);
          else setUnknownUser(true);
        }).catch(err => console.error(err));
      } else {
        // No user is signed in.
        //console.log("User is not signed in to Firebase.");
        setFirebaseAuthCompleted(true);
      }
      
    })
  }, [])

  useEffect(() => {
    (async () => {
      const result = await getRedirectResult(auth);
    })()
    return function cleanup() {
      isLoggingIn.current = false;
    }
  }, [])

  /////////////////////
  // Login Component
  /////////////////////
  checkLiquidLogin();
  return (
    <Container className={style.root} maxWidth="lg">
      { 
        (firebaseAuthCompleted) ?
        <div className={style.rootSubContainer}>
          <img src={LiquidLogoText} alt="Liquid Analytics Logo" className={style.liquidLogoText} />
          <div id="ssoContainer" className={clsx(style.ssoBoxAdjustment, style.ssoContainer)}>
            {isLoggingIn.current && <LinearProgress color='primary'/>}
            <div id="sso">
              {
                (unknownUser)
                  ?
                  <div className={style.authError}>
                    <Typography color="secondary" variant="h4">User Unauthorized</Typography>
                    <br />
                    <p>You are currently not authorized.  Please contact your system administrator.</p>
                    <button className={style.ssoSignInButton}
                      onClick={() => {
                        // trigger login from Auth provider
                        triggerLogin();
                      }}
                    >
                      <img className={style.ssoLoginAnchorImage} src={GoogleAuthLogo} alt="Login with Google" />
                    </button>
		                <button className={style.ssoSignInButton}
                      onClick={() => {
                        // trigger login from Auth provider
                        triggerLiquidLogin();
                      }}
                    >
                       <img className={style.ssoLoginAnchorImage} src={LiquidAuthLogo} alt="Login with Liquid" />
                    </button>

                  </div>
                  :
                  <div className={style.ssoOptionRow}>
                    <Typography color="secondary" variant="h4" style={{ marginBottom: 20 }}>Welcome!</Typography>
                    <button className={style.ssoSignInButton}
                      onClick={() => {
                        // trigger login from Auth provider
                        triggerLogin();
                      }}
                    >
                      <img className={style.ssoLoginAnchorImage} src={GoogleAuthLogo} alt="Login with Google" />
                    </button>
   		              <button className={style.ssoSignInButton}
                      onClick={() => {
                        // trigger login from Auth provider
                        triggerLiquidLogin();
                      }}
                    >
            	       <img className={style.ssoLoginAnchorImage} src={LiquidAuthLogo} alt="Login with Liquid" />
		                </button>
                  </div>
              }
            </div>
          </div>
        </div>
        : <div></div>
      }

    </Container>
  );
};

const loginPageStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
    },
    rootSubContainer: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: "100vh",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: "url(" + LiquidUrl + ")"
    },
    liquidLogoText: {
      width: "10%",
      marginTop: "1vh",
      marginLeft: "2%",
    },
    loadingIndicator: {
      left: `-${theme.spacing(4)}px`,
      transform: `translateY(-${theme.spacing(8)}px)`,
      width: `calc(100% + ${theme.spacing(8)}px)`,
    },
    ssoBoxAdjustment: {
      marginLeft: "auto",
      marginRight: "auto",
      minWidth: "300px",
      width: "40%",
    },
    ssoContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: "block",
      marginTop: "auto",
      marginBottom: "auto",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundColor: theme.palette.common.white,
      border: `1px solid grey`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
    },
    authInfo: {
      textAlign: "center",
    },
    authError: {
      textAlign: "center",
      fontSize: "16px"
    },
    ssoOptionRow: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
    },
    ssoLoginAnchorImage: {
      cursor: "pointer",
    },
    ssoSignInButton: {
      padding: "0",
      border: "none",
      background: "none",
    },
  })
);

export default LoginPage;
