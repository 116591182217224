/********************************************************
 * File: ItemSelectorGrid.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-03-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { DataGrid, GridColDef, GridSelectionModel, GridSortModel } from '@material-ui/data-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionSummary, AccordionDetails, Button, Typography, ButtonGroup,
  InputBase
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { paginatedFetchData } from '../../api/firestoreFetch';
import {
  Unsubscribe, getFirestore, getDoc, doc, DocumentData, writeBatch
} from 'firebase/firestore';
import { AppTheme } from '../../Theme';

type TypeSelectorGridProps = {
  trackerId: string;
  itemType: 'Account' | 'Product' | 'Goal';
  parseItemData: (d: DocumentData) => any;
  cols: GridColDef[];
}

export function SelectorGrid({
  trackerId,
  itemType,
  cols,
  parseItemData
}: TypeSelectorGridProps) {
  const style = styles(AppTheme);
  const db = getFirestore();

  const includeColPath = `Tracker/${trackerId}/Items/summary/${itemType}Include`;

  const includedIndicatorCol: GridColDef = {
    field: 'included',
    headerName: 'Included',
    width: 90,
    type: 'boolean',
    sortable: false,
  };
  const allItemsCols = [includedIndicatorCol].concat(cols);

  // items
  const [includedItems, setIncludedItems] = useState<any[]>([]);
  const [allItems, setAllItems] = useState<any[]>([]);
  //
  const allItemsIncludeStatusUpdated = useRef(false);
  // selection state
  const [selectedIncludes, setSelectedIncludes] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  // whether we are showing the included or all table
  const [included, setIncluded] = useState(true);

  // State for paginated data fetching
  const [orderByVal, setOrderByVal] = useState('name');
  const [ascending, setAscending] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  // need both offsets to compare new one with old to figure out pagination
  // see fetchData()
  const [offset, setOffset] = useState(0); // offset updated by the pagination component
  // paginated list state
  const totalItems = useRef(-1);
  const totalIncludedItems = useRef(-1);
  const currOffset = useRef(0); // offset currently shown in the list
  const firstDoc = useRef<any>();
  const lastDoc = useRef<any>();
  const loading = useRef(false);
  const unsubscribe = useRef<Unsubscribe>(() => void 0);
  const pageSize = 10; // datagrid pagesize

  // FETCH HOOKS
  // fetch trackers asynchronously on load and when order or search values change
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      if (!included) allItemsIncludeStatusUpdated.current = false;
      paginatedFetchData({
        collectionName: included ? includeColPath : itemType,
        countDoc: included ? `Tracker/${trackerId}/Items/summary` : `Aggregate/${itemType.toLowerCase()}`,
        parseCount: included ? (d) => d.get(`counts.${itemType.toLowerCase()}`) ?? 0 : undefined,
        setItems: included ? setIncludedItems : setAllItems,
        loading: loading,
        unsubscribe: unsubscribe,
        pageSize: pageSize,
        parseItem: parseItemData,
        offset: offset,
        orderByVal: orderByVal,
        ascending: ascending,
        searchVal: searchVal,
        totalItems: included ? totalIncludedItems : totalItems,
        currOffset: currOffset,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
      })
    }

    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribe.current();
    }
  }, [ascending, searchVal, offset]);
  // hook for when orderBy, exclude change (reset page / offset when changed)
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      if (!included) allItemsIncludeStatusUpdated.current = false;
      // reset offset to zero
      setOffset(0);
      paginatedFetchData({
        collectionName: included ? includeColPath : itemType,
        countDoc: included ? `Tracker/${trackerId}/Items/summary` : `Aggregate/${itemType.toLowerCase()}`,
        parseCount: included ? (d) => d.get(`counts.${itemType.toLowerCase()}`) ?? 0 : undefined,
        setItems: included ? setIncludedItems : setAllItems,
        loading: loading,
        unsubscribe: unsubscribe,
        pageSize: pageSize,
        parseItem: parseItemData,
        offset: offset,
        orderByVal: orderByVal,
        ascending: ascending,
        searchVal: searchVal,
        totalItems: included ? totalIncludedItems : totalItems,
        currOffset: currOffset,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
      })
    }

    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribe.current();
    }
  }, [orderByVal, included]);


  // after a page of 'all' items is loaded, check if any are included by this tracker
  useEffect(() => {
    if (allItemsIncludeStatusUpdated.current) return void 0;
    allItemsIncludeStatusUpdated.current = true;
    (async () => {
      const updatedItems: any[] = [];
      let atLeastOneInclude = false;
      await Promise.all(allItems.map(async (item, idx) => {
        const itemDoc = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary/${itemType}Include/${item.id}`));
        if (itemDoc.exists()) {
          if (!atLeastOneInclude) atLeastOneInclude = true;
          updatedItems.push({...item, included: true});
        } else {
          updatedItems.push(item);
        }
      }));
      // only update if there was an include that changed an item
      if (atLeastOneInclude) setAllItems(updatedItems);
    })();
  }, [allItems]) 


  // called when a user has selected some items from the 'included' data grid and
  //   then clicks 'exclude selected' button.
  // two cases need to be handled when excluding an item:
  //   1) the item is in the included list because of the filters; add this item to the 
  //     explicit excludes.
  //   2) the item is in the included list because it was previously explicitly included;
  //     just remove it from the explicit include list.
  const excludeSelectedIncludesHandler = async () => {
    if (trackerId == null || (typeof trackerId === 'string' && trackerId.length === 0)) {
      console.error('cannot set includes and excludes on a Tracker without an ID!');
    }

    const batch = writeBatch(db);

    // check if each item is an explicit include
    for (const item of selectedIncludes) {
      if (item.id) {
        if ((typeof item.explicit === 'boolean') && (item.explicit)) {
          // remove from explicitly included list
          const ref = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}IncludeExplicit/${item.id}`);
          batch.delete(ref);
        } else {
          // add to explicitly excluded list
          const ref = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}ExcludeExplicit/${item.id}`);
          batch.set(ref, {id: item.id});
        }
        try {
          await batch.commit();
        } catch (err) {
          console.warn('error writing includes and excludes...');
          console.error(err);
        }
      }
    }
  }

  // called when a user has selected some items from the 'all' data grid and
  //   then clicks 'include selected' button.
  // two cases need to be handled when including an item:
  //   1) the item is in the all list because wasn't included by filters; add this
  //     item to the explicit includes
  //   2) the item is in the all list because it was previously explicitly excluded;
  //     just remove it from the explicit exclude list.
  //   3*) handled: the item is in the all list but is already included. users won't
  //     be able to select these rows, so don't need to handle here.
  const includeSelectedItemsHandler = async () => {
    if (trackerId == null || (typeof trackerId === 'string' && trackerId.length === 0)) {
      console.error('cannot set includes and excludes on a Tracker without an ID!');
    }

    const batch = writeBatch(db);

    // check if each item is an explicit exclude
    for (const item of selectedItems) {
      if (item.id) {
        const itemDoc = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary/${itemType}ExcludeExplicit/${item.id}`));
        if (itemDoc.exists()) {
          // remove from explicitly excluded list
          batch.delete(itemDoc.ref);
        } else {
          // add to explicitly included list
          const ref = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}IncludeExplicit/${item.id}`);
          batch.set(ref, {id: item.id});
        }
        try {
          await batch.commit();
        } catch (err) {
          console.warn('error writing includes and excludes...');
          console.error(err);
        }
      }
    }
  }

  const incExcButton = (
    <Button
      className={clsx(style.incExcButton, style.marginVert)}
      variant='contained'
      color='primary'
      disabled={included ? (selectedIncludes.length === 0) : (selectedItems.length === 0)}
      onClick={() => included ? excludeSelectedIncludesHandler() : includeSelectedItemsHandler()}
    >
      {`${included ? 'Exclude' : 'Include'} Selected`}
    </Button>
  );

  // debounced func only fires after specified ms
  const setSearchValDebounced = debounce(setSearchVal, 300);

  return (
    <div className={style.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="secondary">{itemType + ' Selections'}</Typography>
        </AccordionSummary>
        <AccordionDetails className={style.centeredContainer}>
          <div className={style.gridTop}>
            <div className={style.search}>
              <div className={style.searchIcon}>
                <Search />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: style.inputRoot,
                  input: style.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setSearchValDebounced(e.target.value)}
              />
            </div>
            <ButtonGroup className={style.marginVert} disableElevation size='medium'>
              <Button 
                variant={included ? 'contained' : 'outlined'} 
                color='primary'
                onClick={() => {
                  if (!included) setIncluded(true);
                }}
              >
                Included
              </Button>
              <Button 
                variant={included ? 'outlined' : 'contained'} 
                color='primary'
                onClick={() => {
                  if (included) setIncluded(false);
                }}
              >
                All
              </Button>
            </ButtonGroup>
          </div>
          <DataGrid
            className={style.dataGrid}
            rows={included ? includedItems : allItems}
            rowCount={included ? totalIncludedItems.current : totalItems.current}
            columns={included ? cols : allItemsCols}
            pageSize={pageSize}
            checkboxSelection
            disableSelectionOnClick
            loading={loading.current}
            disableColumnMenu
            isRowSelectable={params => {
              if (included) return true;
              return !params.row.included;
            }}
            rowsPerPageOptions={[pageSize]}

            // update the query offset on page change
            onPageChange={page => setOffset(page * pageSize)}
            paginationMode='server'
            
            // sorting
            sortingMode='server'
            //sortModel={sortModel}
            onSortModelChange={(model: GridSortModel) => {
              // datagrid returns empty array when header is clicked a third time which
              //   makes the grid go back to initial sort state. only update our
              //   orderBy and ascending for a valid sort.
              if (model.length > 0) {
                setOrderByVal(model[0].field);
                setAscending(model[0].sort === 'asc' ? true : false);
              }
            }}

            // selection
            onSelectionModelChange={(selects: GridSelectionModel) => {
              const selectedIds = selects.map(s => s.toString());
              if (included) {
                if (selects.length === 0) {
                  setSelectedItems([]);
                } else {
                  setSelectedIncludes(includedItems.filter(i => selectedIds.includes(i.id)));
                }
              } else {
                if (selects.length === 0) {
                  setSelectedItems([]);
                } else {
                  setSelectedItems(allItems.filter(i => selectedIds.includes(i.id)));
                }
              }
            }}
          />
          {incExcButton}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


const styles = makeStyles((theme: any) => ({
  root: {
    width: '95%',
  },
  fonts: {
    color: "#455A64",
  },
  dataGrid: {
    height: '100%',
    width: '100%',
    color: '#455A64'
  },
  centeredContainer: {
    width: '95%',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  incExcButton: {
    width: 230,
    alignSelf: 'flex-end'
  },
  marginVert: {
    marginTop: 5,
    marginBottom: 5
  },
  gridTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  // search bar
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#e3e3e3',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'background-color 0.2s',
    marginTop: theme.spacing(1),
    marginBottom: 5,
    marginRight: 20,
    width: '40%',
    height: 37,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    color: '#455A64',
    paddingLeft: theme.spacing(1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: '#455A64',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));