/********************************************************
 * File: Styled.Menu.tsx
 * Project: @liquid-mc/ui
 * File Created: 08-19-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 * 
 * Copyright © 2021 Liquid Analytics
*********************************************************/


import React from 'react';
import _ from 'lodash';
import { emphasize, withStyles, Theme } from '@material-ui/core/styles';
import { 
    Menu, MenuItem, MenuList, MenuListProps, MenuProps,
    ListItemIcon, ListItemText, SvgIconProps
} from '@material-ui/core';

/**
 * Styled MenuList
 */
export const StyledMenuList = withStyles({
    root: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuListProps) => (
    <MenuList
        variant="menu"
        {...props}
    />
));

/**
 * Styled Menu
 */
export const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

/**
 * Styled MenuItem
 */
const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: theme.palette.mc.main,
        '&:focus': {
            backgroundColor: theme.palette.mc.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.status.selectedText,
            },
        },
    },
}))(MenuItem);


// Menu item that supports sub menu!
type Item = {
    id?: string,
    label: string;
    children?: Item[];
};

interface StyledMenuItemProps {
    id?: string | undefined;
    label: string | undefined;
    subLabel?: string | undefined;
    Icon?: React.ElementType<SvgIconProps<"svg", {}>> | React.FC<React.SVGProps<SVGSVGElement>> | undefined;
    iconColor?: string;
    childrenItems?: Item[];
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<any>, id?: string) => void;
}

export const AppStyledMenuItem = React.forwardRef<any, StyledMenuItemProps>((
    {
        id: id,
        label: parentLabel,
        subLabel: parentSubLabel,
        Icon: Icon,
        iconColor: iconColor,
        childrenItems: parentChildrenItems = [],
        disabled: parentDisabled,
        onClick
    },
    ref
) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isSubMenuOpen = Boolean(Boolean(anchorEl));
    const hasChildrenItems = parentChildrenItems?.length || false;
    const isLeafNode = !hasChildrenItems;

    const handleMouseEnter = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<any>, id?:string) => {
        event.stopPropagation();
        if (onClick && isLeafNode) {
            if (id) onClick(event, id); else onClick(event);
        }
    };

    return (
    <StyledMenuItem
        key={`${parentLabel}-styled-menu-item`}
        ref={ref}
        disableRipple
        onClick={(e) => handleClick(e, id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
        disabled={parentDisabled}
    >
        {Icon &&
        <ListItemIcon>
            <Icon htmlColor={iconColor}/>
        </ListItemIcon>
        }
        <ListItemText primary={parentLabel} secondary={parentSubLabel}/>
        {hasChildrenItems && (
            <>
                <Menu
                    // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
                    key={`${parentLabel}-sub-menu`}
                    style={{ pointerEvents: "none" }}
                    anchorEl={anchorEl}
                    open={isSubMenuOpen}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                {/* reset pointer event here so that the menu items could receive mouse events */}
                <div style={{ pointerEvents: "auto" }}>
                    {parentChildrenItems.map((item, idx) => {
                        const { id, label, children } = item;
                        return (
                            <AppStyledMenuItem
                                id={id}
                                key={`label-${idx}`}
                                label={label}
                                childrenItems={children}
                                onClick={onClick}
                            />
                        );
                    })}
                </div>
                </Menu>
            </>
        )}
    </StyledMenuItem>
    );
});