import { 
  getFirestore, collection, onSnapshot, Unsubscribe,
  doc, getDoc, DocumentSnapshot,
  query, where, limit, orderBy, limitToLast, startAfter, endBefore
} from 'firebase/firestore';
import { first as loFirst, last as loLast, get as loGet } from 'lodash';

type TypeFetchParams = {
  collectionName: string;
  countDoc: string;
  parseCount?: (d: DocumentSnapshot) => number;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  loading: React.MutableRefObject<boolean>;
  unsubscribe: React.MutableRefObject<Unsubscribe>;
  parseItem: (item: DocumentSnapshot) => any;

  // query params
  orderByVal: string;
  ascending: boolean;
  searchVal?: string;
}

export async function fetchData({
  collectionName,
  setItems,
  loading,
  unsubscribe,
  parseItem,
  orderByVal,
  ascending,
  searchVal,
}: TypeFetchParams) {
  const db = getFirestore();
  // unsub from old FireStore listeners if any
  unsubscribe.current();
  console.log('FETCHING!'); // debugging
  let q = query(
    collection(db, collectionName),
    orderBy(orderByVal, ascending ? 'asc' : 'desc'),
  );

  // if there is a search value, add it to the query
  if (typeof searchVal === 'string' && searchVal.length > 0) {
    // only the first 3 tokens because trigram search
    const search = searchVal.slice(0,3).toLowerCase();
    q = query(q, where('token', 'array-contains', search));
  }

  unsubscribe.current = onSnapshot(
    // query
    q,
    // success callback
    (items) => {
      loading.current = false;
      setItems(items.docs.map(t => parseItem(t)));
    },
    // error callback
    (err) => {
      console.error(err);
      loading.current = false;
    }
  );
}

// all the params needed for paginated data fetch
type TypePaginatedFetchParams = {
  collectionName: string;
  countDoc: string;
  parseCount?: (d: DocumentSnapshot) => number;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  loading: React.MutableRefObject<boolean>;
  unsubscribe: React.MutableRefObject<Unsubscribe>;
  parseItem: (item: DocumentSnapshot) => any;

  // query params
  orderByVal: string;
  ascending: boolean;
  searchVal: string;

  // pagination
  pageSize: number;
  offset: number;
  totalItems: React.MutableRefObject<number>;
  currOffset: React.MutableRefObject<number>;
  firstDoc: React.MutableRefObject<any | undefined>;
  lastDoc: React.MutableRefObject<any | undefined>;
}

// gets the total number of items first if not already set
export async function paginatedFetchData(params: TypePaginatedFetchParams) {
  params.loading.current = true;
  if (params.totalItems.current === -1) {
    const db = getFirestore();
    const countDoc = await getDoc(doc(db, params.countDoc));
    let total: null | number = null;
    if (params.parseCount && typeof params.parseCount === 'function') {
      total = params.parseCount(countDoc);
    } else {
      total = countDoc.get('count') ?? 0;
    }
    if (typeof total !== 'number') {
      total = 0;
    }
    params.totalItems.current = total;
  }
  _paginatedFetchData(params);
}

const _paginatedFetchData = ({
  collectionName,
  setItems,
  loading,
  unsubscribe,
  pageSize,
  parseItem,
  offset,
  orderByVal,
  ascending,
  searchVal,
  totalItems,
  currOffset,
  firstDoc,
  lastDoc,
}: TypePaginatedFetchParams) => {
  const db = getFirestore();
  // unsub from old FireStore listeners if any
  unsubscribe.current();

  const firstDocOrderVal = loGet(firstDoc.current, orderByVal, '');
  const lastDocOrderVal = loGet(lastDoc.current, orderByVal, '');

  console.log('FETCHING!'); // debugging
  let q = query(
    collection(db, collectionName),
    orderBy(orderByVal, ascending ? 'asc' : 'desc'),
  );

  // if there is a search value, add it to the query
  if (typeof searchVal === 'string' && searchVal.length > 0) {
    // only the first 3 tokens because trigram search
    const search = searchVal.slice(0,3).toLowerCase();
    q = query(q, where('token', 'array-contains', search));
  }

  // pagination!
  // paginate by starting after the last doc on the previous page if
  //   page forward is clicked, before first doc if backwards, etc.
  if (offset == 0) { // first page
    q = query(q, limit(pageSize));
  } else if (offset > currOffset.current) { // next page or last page
    if ((totalItems.current - (currOffset.current + pageSize)) < pageSize) { // last page
      // mod to get # on final page
      q = query(q, limitToLast(totalItems.current % pageSize));
    } else { // next page
      q = query(q, limit(pageSize), startAfter(lastDocOrderVal));
    }
  } else { // previous page (first page already handled above)
    q = query(q, limitToLast(pageSize), endBefore(firstDocOrderVal));
  };

  unsubscribe.current = onSnapshot(
    // query
    q,
    // success callback
    (items) => {
      //console.log(items.docs.map(doc => doc.id));
      const tempItems: any[] = [];
      items.docs.forEach(t => tempItems.push(parseItem(t)));
      loading.current = false;
      firstDoc.current = loFirst(tempItems);
      lastDoc.current = loLast(tempItems);
      setItems(tempItems);
    },
    // error callback
    (err) => {
      console.error(err);
      loading.current = false;
    }
  );
  currOffset.current = offset;
};

export async function fetchTrackerTemplate(templateId: string) {
  const db = getFirestore();
  const template = await getDoc(doc(db, `DataTableTemplate/${templateId}`));
  if (!template.exists()) {
    console.error(`Error: Data Table Template with ID '${templateId}' does not exist!`);
    return;
  }
  return template;
}