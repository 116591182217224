/********************************************************
 * File: ListPage.tsx
 * Project: @liquid-mc/ui
 * File Created: 08-31-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React from 'react';
import _ from 'lodash';
import { TypeTracker } from '../../typings/tracker.types';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { TrackerList } from '../Tracker/TrackerList';
import { AppTheme } from '../../Theme';

function ListPage(props:any) {
	// Style
	const style = listPageStyle(AppTheme);

	//const db = firebase.firestore();

	const onEditClick = (t: TypeTracker) => {
		props.history.push({
			pathname: '/TrackerDetail',
			state: {'data': t},
		});
	}

	const onMetricsClick = (t: TypeTracker) => {
		props.history.push({
			pathname: '/TrackerMetrics',
			state: {'data': t}
		})
	}

	const onAddClick = () => {
		props.history.push({ 
			pathname: '/TrackerDetail',
			state: {'data': {id: ''}},
		});
	}

	return (
		<TrackerList
			onRowClick={onMetricsClick}
			onEditClick={onEditClick}
			onAddClick={onAddClick}
		/>
	)
}

export default ListPage;

/** MUI STYLE */
const listPageStyle = makeStyles((theme: Theme) => createStyles({

}));