/********************************************************
 * File: SysInfo.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-13-2021
 * Author: Fisher Moritzburke
 * bryan.nagle@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    getFirestore, getDoc, doc
} from 'firebase/firestore';
import { AppTheme } from '../../Theme';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';


type TypeFieldProps = {
  children?: React.ReactNode;
  label: string;
  value: string;
}
function Field({ label, value }: TypeFieldProps) {
  const style = styles();
  return (
    <div className={style.field}>
      <Typography color="secondary" className={style.fieldLabel}>
        {label}
      </Typography>
      <Typography color="secondary" className={style.fieldVal}>
        {value}
      </Typography>
    </div>
  )
}

type TypeSysInfoProps = {
  children?: React.ReactNode;
  trackerId: string;
}
function SysInfo({ trackerId }: TypeSysInfoProps) {
  const db = getFirestore();
  const style = styles();

  let [createdAt, setCreatedAt] = useState('');
  let [createdBy, setCreatedBy] = useState('');
  let [updatedAt, setUpdatedAt] = useState('');
  let [updatedBy, setUpdatedBy] = useState('');
  if (typeof trackerId === 'string' && trackerId.length > 0) {
    (async () => {
      const trackerDoc = await getDoc(doc(db, `Tracker/${trackerId}`));
      const ca = trackerDoc.get('createdAt');
      if (ca) setCreatedAt(ca.toDate().toLocaleString());
      const ua = trackerDoc.get('createdAt');
      if (ua) setUpdatedAt(ua.toDate().toLocaleString());

      setCreatedBy(trackerDoc.get('createdBy') ?? '');
      setUpdatedBy(trackerDoc.get('updatedBy') ?? '');
    })();
  }

  return (
    <div className={style.root}>
      <div className={style.centered}>
        <Typography color="secondary" variant='h5'>System Info</Typography>

        <div className={style.fieldsContainer}>
          <Field label='Created By:' value={createdBy}/>
          <Field label='Created At:' value={createdAt}/>
          <Field label='Updated By:' value={updatedBy}/>
          <Field label='Updated At:' value={updatedAt}/>
          <Field label='ID:' value={trackerId}/>
        </div>

      </div>
    </div>
  );
}

const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    alignItems: 'flex-start',
    padding: '0px 5px 10px 5px',
    margin: '5px 0px 5px 0px',
  },
  fieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 20px 0px 0px',
    marginBottom: '20px',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '10px 30px 10px 30px',
  },
  fieldLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    textalign: 'center',
    minWidth: 90
  },
  fieldVal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px solid grey',
    borderRadius: 3,
    marginLeft: 10,
    height: 20,
    minWidth: 150,
    padding: '5px 12px 5px 12px'
  },
}));

export default SysInfo;