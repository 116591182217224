import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';


import { AppTheme } from "./Theme";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


//Create App Theme
const theme = createTheme(AppTheme);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>      
    </ThemeProvider>,
    document.getElementById('root'));