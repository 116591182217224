/********************************************************
 * File: TrackerMetricsPage.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-01-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import { AppTheme } from '../../Theme';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { Unsubscribe } from '@firebase/firestore';
import { fetchTrackerTemplate, fetchData } from '../../api/firestoreFetch';
import { ArrowBack } from '@material-ui/icons';
import SysInfo from '../core/SysInfo';

type GridColWithSeq = GridColDef & { sequence: number };

function TrackerMetricsPage(props: any) {
  const style = styles(AppTheme);

  // tracker state
  const [trackerId, setTrackerId] = useState('');
  const [trackerName, setTrackerName] = useState('');
  const [trackerDescription, setTrackerDescription] = useState('');
  const [trackerTemplateId, setTrackerTemplateId] = useState('');
  useEffect(() => {
    if (props.location.state.data) {
      const t = props.location.state.data;
      if (typeof t.id === 'string' && t.id.length > 0) {
        setTrackerId(t.id);
      }
      if (typeof t.name === 'string' && t.name.length > 0) {
        setTrackerName(t.name);
      }
      if (typeof t.description === 'string' && t.description.length > 0) {
        setTrackerDescription(t.description);
      }
      if (typeof t.trackerTemplateId === 'string' && t.trackerTemplateId.length > 0) {
        setTrackerTemplateId(t.trackerTemplateId);
      }
    }
  }, [props.location]);

  ///////////////////////////
  // metrics data table state
  ///////////////////////////

  // get columns from the tracker template (wait for 'trackerId' to be set)
  const [cols, setCols] = useState<GridColDef[]>([]);
  useEffect(() => {
    if (!(trackerTemplateId.length > 0)) return;
    (async () => {
      const template = await fetchTrackerTemplate(trackerTemplateId);
      if (!template) return;
      const cs = template.get('column');
      if (!Array.isArray(cs)) {
        console.error(`Error: Tracker Template with ID '${trackerTemplateId}' has malformed 'column' field`);
        return;
      }

      const maxWidth = 1030;
      const colWidth = maxWidth / cs.length;
      const gridCols: GridColWithSeq[] = [];
      cs.forEach(c => {
        if ((typeof c.key != 'string') || (typeof c.display != 'string')) return;

        gridCols.push({
          field: c.key,
          headerName: c.display,
          width: colWidth,
          sortable: false,
          sequence: (typeof c.sequence === 'number') ? c.sequence : 10,
        });

      });
      setCols(gridCols.sort((a, b) => a.sequence - b.sequence));
    })();
  }, [trackerId])

  // get rows (wait until 'cols' data is set)
  const [rows, setRows] = useState<any[]>([]);
  // paginated fetch state
  const unsubscribe = useRef<Unsubscribe>(() => void 0);
  const loading = useRef(false);
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      fetchData({
        collectionName: `Tracker/${trackerId}/DataTable`,
        countDoc: `Tracker/${trackerId}`,
        parseCount: (d) => d.get(`summary.dataTableCount`) ?? 0,
        setItems: setRows,
        loading: loading,
        unsubscribe: unsubscribe,
        parseItem: (d) => ({id: `${d.id}-${d.get('goalType')}`, ...d.data()}),
        orderByVal: 'goalType',
        ascending: true,
      })
    }
    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribe.current();
    }
  }, [cols]);

  const editTracker = () => {
    props.history.push({
			pathname: '/TrackerDetail',
			state: props.location.state,
		});
  }

  const listPage = () => {
    props.history.push({
			pathname: '/TrackerList',
		});
  }
  
  return (
    <Paper classes={{root: style.root}}>
      <Box display="flex" className={style.header}>
        <div className={style.leftRightBox}>
          <Button className={style.backButton} color="inherit" onClick={listPage}>
            <ArrowBack />
          </Button>
        </div>
        <Typography color="secondary" variant='h4' className={style.title}>
          {trackerName}
        </Typography>
        <div className={style.leftRightBox}>
          <Button className={style.editButton} color="inherit" onClick={editTracker}>
            <Typography color="secondary" variant='body1' className={style.editTracker}>
              {'Edit\nTracker'}
            </Typography>
          </Button>
        </div>
      </Box>
      <div className={style.descriptionContainer}>
        <Typography color="secondary" variant='body1'>
          {trackerDescription}
        </Typography>
      </div>
      <DataGrid
        classes={{root: style.datagridRoot, columnHeader: style.datagridHeader}}
        rows={rows}
        columns={cols}
        pageSize={10}
        disableSelectionOnClick
        loading={loading.current}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        headerHeight={40}
        showColumnRightBorder
      />
      <SysInfo trackerId={trackerId} />
    </Paper>
  );
}

export default TrackerMetricsPage;

const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 1048,
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  datagridRoot: {
    height: '65%',
    width: '100%',
    padding: '5px',
    marginBottom: '40px',
    color: '#455A64'
  },
  datagridHeader: {
    fontSize: 17,
    backgroundColor: '#ebebeb',
    color: '#455A64'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '60px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: "10px",
  },
  title: {
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  descriptionContainer: {
    display: 'flex',
    margin: '8px 8px 24px 8px',
  },
  editButton: {
    minHeight: '60px'
  },
  editTracker: {
    color: 'white',
    textTransform: 'none',
    lineHeight: '18px'
  },
  backButton: {
    minHeight: '60px',
    minWidth: '60px'
  },
  leftRightBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 80,
    height: '100%',
  }
}));