/********************************************************
 * File: Theme.tsx
 * Project: @liquid-perform/mc
 * File Created: 08-19-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 *
 * Copyright © 2021 Liquid Analytics
 *********************************************************/

import { createTheme } from '@material-ui/core/styles';

const palette = {
  mc: {
    light: "#ECEFF1",
    /** Use this main backgrounds or accentuating elements */
    main: "#607d8b",
    dark: "#0B79D0",
    contrastText: "#FFFFFF",
    /** Use this for title text or text which is prominent in the component */
    titleText: "#455A64",
    descriptionText: "#455A64",
    hintText: "#90a4ae",
    textFieldLight: '#78909c',
    borderColor: '#455A64'
  },
  primary: {
    light: "#64B6F7",
    main: "#2196F3",
    dark: "#0B79D0",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: "#455A64",
    main: "#455A64",
    dark: "#455A64",
    contrastText: "#FFFFFF",
  },
  error: {
    light: "#e8174f",
    main: "#e8174f",
    dark: "#e8174f",
    contrastText: "#e8174f",
  },
  warning: {
    light: "#FFB547",
    main: "#FF9800",
    dark: "#C77700",
    contrastText: "rgba(0,0,0,87)",
  },
  info: {
    light: "#64B6F7",
    main: "#2196F3",
    dark: "#0B79D0",
    contrastText: "#FFFFFF",
  },
  success: {
    light: "#7BC67E",
    main: "#4CAF50",
    dark: "#3B873E",
    contrastText: "rgba(0,0,0,87)",
  },
  action: {
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.9,
  },
};

export const AppTheme = createTheme({
  palette: palette,
  status: {
    selectedText: "#455A64",
    disabledText: "#a8bec7",
    hoverBackground: "#90A4AE",
    loading: "#ae4bf4", // for loading indicator
    dirtyFlagStatus: '#4caf50'
  },
  breakpoints: {
    values: {
      xs: 0,
      mobileS: 320, // iphone5 viewport = 320px
      mobileM: 375, // iphone X viewport = 375px
      mobileL: 425, // iphone* plus viewport = 425px
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1920,
      ipad: 768,
      ipadpro: 1024,
    }
  },
  typography: {
    fontFamily: ["Roboto", "Arial", "Helvetica", "sans-serif", "system-ui"].join(','),
  },

  /** overrides */
  overrides: {
    MuiSelect: {
      selectMenu: {
        color: '#455A64'
      },
    }
  },
});

// colors for chips
export const itemColors = {
  account: {
    label: '#BBDEFB',
    scope: '#1565C0'
  },
  product: {
    label: '#FFF9C4',
    scope: '#F9A825'
  },
  goal: {
    label: '#C8E6C9',
    scope: '#2E7D32'
  }
}
