/********************************************************
 * File: TrackerDetailPage.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-01-2021
 * Author: Bryan Nagle
 * bryan.nagle@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
    Theme, Paper, Button, Box, TextField, Select, Popover,
    MenuItem, Switch, FormControlLabel, Checkbox, Input, CircularProgress, Typography 
} from '@material-ui/core';
import { TypeTracker } from 'typings/tracker.types';
import {
    getFirestore, collection, onSnapshot, getDoc, doc, updateDoc, DocumentData,
    setDoc, DocumentSnapshot, getDocs, deleteDoc
} from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
import GoalFiltersSection from '../form/GoalFiltersSection';
import AccountFiltersSection from '../form/AccountFiltersSection';
import ProductFiltersSection from '../form/ProductFiltersSection';
import SysInfo from '../core/SysInfo';
import { AppTheme } from '../../Theme';
import { ArrowBack, Done, Warning } from '@material-ui/icons';


function TrackerDetailPage(props: any) {
    const db = getFirestore();
    const auth = getAuth();

    // Style
    const style = trackerDetailPageStyle(AppTheme);

    const listPage = () => {
        props.history.push({
            pathname: '/TrackerList',
        });
    }

    const handleCancelClick = (event: any) => {
        if (isStateDirty) {
            openPopover(
                "Discard unsaved changes?",
                listPage,
                () => void 0 // nothing to do
            );
        } else {
            listPage();
        }
    }

    // Generic handler that updates the passed in hook
    const genericHandler = (updateHook: any) => {
        return (event: any) => {
            updateHook(_.get(event, 'target.value'));
        }
    };

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverMessage, setPopoverMessage] = useState('');
    const [popoverOnOk, setPopoverOnOk] = useState<()=>void | undefined>();
    const [popoverOnCancel, setPopoverOnCancel] = useState<()=>void | undefined>();

    // states for showing save spinner, success indicator
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const saveCalled = useRef(false);

    const loaded = useRef(false);
    const [isStateDirty, setIsStateDirty] = useState(false);

    // Data States
    const [tracker, setTracker] = useState({} as TypeTracker);
    const [name, setName] = useState('');
    const [trackerTemplates, setTrackerTemplates] = useState<any[]>([]);
    const [description, setDescription] = useState('');
    const [trackerTemplateId, setTrackerTemplateId] = useState('');
    const [active, setActive] = useState(true);
    const [isPrivate, setIsPrivate] = useState(false);

    // load hierarchy levels and names
    const orgLevels = useRef<{[key: number]: any}>({});
    const phLevels = useRef<{[key: number]: any}>({});
    useEffect(() => {
        // org levels
        (async () => {
            const orgDoc = await getDoc(doc(db, 'Attribute/orgLevel'));
            if (!orgDoc.exists()) return void 0;
            const levels = orgDoc.get('enums');
            if (_.isNil(levels) || !Array.isArray(levels)) return void 0;
            const tempOrgMap: any = {};
            levels.forEach(lvl => {
                if (typeof lvl.level !== 'number') return void 0;
                tempOrgMap[lvl.level] = lvl;
            })
            orgLevels.current = tempOrgMap;
        })();

        // ph levels
        (async () => {
            const phDoc = await getDoc(doc(db, 'Attribute/phLevel'));
            if (!phDoc.exists()) return void 0;
            const levels = phDoc.get('enums');
            if (_.isNil(levels) || !Array.isArray(levels)) return void 0;
            const tempPhMap: any = {};
            levels.forEach(lvl => {
                if (typeof lvl.level !== 'number') return void 0;
                tempPhMap[lvl.level] = lvl;
            })
            phLevels.current = tempPhMap;
        })();
    }, [])

    //////////////////////////////
    // ITEM FILTER STATES
    //////////////////////////////
    const lastMonth = new Date();
    lastMonth.setFullYear(lastMonth.getFullYear(), lastMonth.getMonth() - 1) // set to same date last month
    // goals
    const [goalAttributes, setGoalAttributes] = useState<any[]>([]);
    const [excludeGoalAttributes, setExcludeGoalAttributes] = useState<any[]>([]);
    const [goalOrgs, setGoalOrgs] = useState<any[]>([]);
    // goal period format compatible with the DateRange component
    const [goalPeriod, setGoalPeriod] = useState<any[]>([{
        startDate: lastMonth,
        endDate: new Date(),
        key: 'selection'
    }]);
    // accounts
    const [accountAttributes, setAccountAttributes] = useState<any[]>([]);
    const [excludeAccountAttributes, setExcludeAccountAttributes] = useState<any[]>([]);
    const [orgs, setOrgs] = useState<any[]>([]);
    const [excludeOrgs, setExcludeOrgs] = useState<any[]>([]);
    // products
    const [productAttributes, setProductAttributes] = useState<any[]>([]);
    const [excludeProductAttributes, setExcludeProductAttributes] = useState<any[]>([]);
    const [phs, setPhs] = useState<any[]>([]);
    const [excludePhs, setExcludePhs] = useState<any[]>([]);


    // track whether data has changed (state is dirty) to enable save
    useEffect(() => {
        // only update dirty state once everything else is loaded so
        //   it isn't triggered by data loads
        if (loaded.current && !isStateDirty) {
            setIsStateDirty(true);
        }
    }, [
        name, trackerTemplateId, description, active, isPrivate,
        goalAttributes, accountAttributes, excludeAccountAttributes,
        orgs, excludeOrgs, productAttributes, excludeProductAttributes,
        phs, excludePhs, excludeGoalAttributes
    ]);

    const deleteCollectionDocs = async (tSnap: DocumentSnapshot, path: string) => {
        const snaps = (await getDocs(collection(db, `${tSnap.ref.path}/DataTable`))).docs;
        snaps.forEach(snap => {
            deleteDoc(snap.ref);
        })
    }

    const deleteTracker = async () => {
        if (_.isEmpty(tracker.id)) {
            console.warn('Failed to delete tracker: no ID!');
            return;
        }

        const tSnap = await getDoc(doc(db, `Tracker/${tracker.id}`));
        if (!tSnap.exists()) {
            console.warn(`Failed to delete tracker with ID ${tracker.id}: document does not exist!`);
            return;
        }

        listPage();

        // first need to delete all subcollections...
        // DataTable
        deleteCollectionDocs(tSnap, `${tSnap.ref.path}/DataTable`);
        // included accounts
        deleteCollectionDocs(tSnap, `${tSnap.ref.path}/Items/summary/AccountInclude`);
        // explicity excluded accounts
        deleteCollectionDocs(tSnap, `${tSnap.ref.path}/Items/summary/AccountExcludeExplicit`);
        // explicity included accounts
        deleteCollectionDocs(tSnap, `${tSnap.ref.path}/Items/summary/AccountIncludeExplicit`);
        // summary doc
        const summarySnap = await getDoc(doc(db, `${tSnap.ref.path}/Items/summary`));
        deleteDoc(summarySnap.ref);
        // main tracker doc
        deleteDoc(tSnap.ref);
    }

    const saveTracker = async () => {
        // set indicator showing save has been called at least once
        if (!saveCalled.current) saveCalled.current = true;
        setIsSaving(true);

        if (_.isEmpty(name)) {
            setIsSaving(false);
            openPopover('You must provide a name for this Tracker!');
            console.warn('Failed to save tracker: no name');
            return;
        }

        // check if updating or creating new tracker
        let tSnap: DocumentSnapshot | undefined;
        if (!_.isEmpty(tracker.id)) tSnap = await getDoc(doc(db, `Tracker/${tracker.id}`));
        const updating = (tSnap && tSnap.exists()) ? true : false;

        const d = new Date();
        const t = {
            updatedAt: d,
            updatedBy: auth.currentUser?.email ?? 'unknown',
            status: active,
            private: isPrivate,
            filter: {
                account: accountAttributes.map(a => ({ // include filters
                    key: a.key,
                    operator: 'OR', // TODO: when to use 'AND'?
                    value: a.value,
                    display: a.display,
                })).concat(excludeAccountAttributes.map(a => ({ // concat the excludes
                    key: a.key,
                    operator: 'NOT',
                    value: a.value,
                    display: a.display,
                }))),
                product: productAttributes.map(a => ({
                    key: a.key,
                    operator: 'OR', // TODO: when to use 'AND'?
                    value: a.value,
                    display: a.display,
                })).concat(excludeProductAttributes.map(a => ({ // concat the excludes
                    key: a.key,
                    operator: 'NOT',
                    value: a.value,
                    display: a.display,
                }))),
                org: orgs.map(a => ({
                    key: a.key,
                    operator: 'OR', // TODO: when to use 'AND'?
                    value: a.value,
                    display: a.display
                })).concat(excludeOrgs.map(a => ({ // concat the excludes
                    key: a.key,
                    operator: 'NOT',
                    value: a.value,
                    display: a.display
                }))),
                ph: phs.map(a => ({
                    key: a.key,
                    operator: 'OR', // TODO: when to use 'AND'?
                    value: a.value,
                    display: a.display
                })).concat(excludePhs.map(a => ({ // concat the excludes
                    key: a.key,
                    operator: 'NOT',
                    value: a.value,
                    display: a.display
                }))),
                goal: goalAttributes.map(a => ({
                    key: a.key,
                    operator: 'OR', // TODO: when to use 'AND'?
                    value: a.value,
                    display: a.display,
                })).concat(excludeGoalAttributes.map(a => ({ // concat the excludes
                    key: a.key,
                    operator: 'NOT',
                    value: a.value,
                    display: a.display,
                })))
            }
        }
        if (!_.isEmpty(name)) _.set(t, 'name', name);
        if (!_.isEmpty(description)) _.set(t, 'description', description);
        if (!_.isEmpty(trackerTemplateId)) _.set(t, 'trackerTemplateId', trackerTemplateId);

        // tSnap will always be defined if we are updating... just need to make TS happy
        if (updating && typeof tSnap !== 'undefined') {
            try {
                updateDoc(tSnap.ref, t);
                setIsStateDirty(false);
            } catch (e) {
                setSaveSuccess(false);
                console.error('failed to update tracker');
                console.error(e);
            }
        } else { // doc doesn't exist, we are creating a new tracker
            const newTrackerRef = doc(collection(db, 'Tracker'));
            _.set(t, 'id', newTrackerRef.id);
            _.set(t, 'createdBy', auth.currentUser?.email ?? 'unknown');
            _.set(t, 'createdAt', d);
            try {
                setDoc(newTrackerRef, t);
                setTracker({...tracker, id: newTrackerRef.id});
                setIsStateDirty(false);
            } catch (e) {
                setSaveSuccess(false);
                console.error('failed to create tracker');
                console.error(e);
            }
        }
        setIsSaving(false);
    }


    // Load Tracker Templates on Attach
    useEffect(() => {
        return onSnapshot(collection(db, "TrackerTemplate"), (snap) => {
            setTrackerTemplates(_.map(snap.docs, doc => _.assign(doc.data(), { id: doc.id })));
        });
    }, []);

    const parseFilter = (f: DocumentData, concatVal: boolean) => {
        // default for both display and label is `display`, fall back to `value`
        const disp = f.display ?? f.value;
        let label = f.display ?? f.value;
        // if concat (used for orgs and phs), concat the value to the label
        if (concatVal && f.display) {
            label = `${f.display}: ${f.value}`;
        }
        return ({
            key: f.key ?? '',
            value: f.value ?? '',
            display: disp,
            scope: f.key ?? '',
            label: label
        });
    }

    const itemSetterMap: {[key: string]: React.Dispatch<React.SetStateAction<any[]>>} = {
        account: setAccountAttributes,
        accountExclude: setExcludeAccountAttributes,
        org: setOrgs,
        orgExclude: setExcludeOrgs,
        product: setProductAttributes,
        productExclude: setExcludeProductAttributes,
        ph: setPhs,
        phExclude: setExcludePhs,
        goal: setGoalAttributes,
        goalExclude: setExcludeGoalAttributes
    }

    const parseItemFilters = (filters: any, item: string, tempTracker: any) => {
        const concatVal = ['ph', 'org'].includes(item);
        if (filters[item] && Array.isArray(filters[item])) {
            const fs = filters[item]
                .filter((f: any) => (typeof f.value === 'string') && ['AND', 'OR'].includes(f.operator))
                .map((f: any) => parseFilter(f, concatVal));
            tempTracker[`${item}Filters`] = fs;
            itemSetterMap[item](fs);

            const fsExclude = filters[item]
                .filter((f: any) => (typeof f.value === 'string') && f.operator === 'NOT')
                .map((f: any) => parseFilter(f, concatVal));
            tempTracker[`${item}ExcludeFilters`] = fsExclude;
            itemSetterMap[`${item}Exclude`](fsExclude);
        }
    }

    const loadFilters = async (tracker: any) => {
        const id = _.get(tracker, 'id');
        if (_.isEmpty(id)) { // empty ID when adding new Tracker, nothing to load
            loaded.current = true;
            return; 
        }
        const tSnap = await getDoc(doc(db, `Tracker/${id}`));
        const filters = tSnap.get('filter') ?? null;
        if (filters != null) {
            const temp: TypeTracker = {...tracker};
            parseItemFilters(filters, 'account', temp);
            parseItemFilters(filters, 'product', temp);
            parseItemFilters(filters, 'org', temp);
            parseItemFilters(filters, 'ph', temp);
            parseItemFilters(filters, 'goal', temp);
            setTracker(temp);
        }
        loaded.current = true;
    }

    type TypeInfoPopoverProps = {
        message: string;
        onOk?: ()=>void;
        onCancel?: ()=>void;
    }

    function InfoPopover({ message, onOk, onCancel }: TypeInfoPopoverProps) {
        return (
            <Popover
                id='info-popover'
                open={popoverOpen}
                anchorReference='none'
                className={style.popover}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper className={style.popoverPaper}>
                    <Typography color="secondary" className={style.popoverMessage}>
                        {message}
                    </Typography>
                    <div className={style.popoverButtonRow}>
                        {onCancel && <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                onCancel();
                                setPopoverOpen(false);
                            }}
                            className={style.popoverCancel}
                        >
                            Cancel
                        </Button>}
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                onOk && onOk();
                                setPopoverOpen(false);
                            }}
                            className={style.popoverClose}
                        >
                            Ok
                        </Button>
                    </div>
                </Paper>
            </Popover>
        );
    }

    const openPopover = (message: string, onOk?: ()=>void, onCancel?: ()=>void) => {
        setPopoverMessage(message);
        setPopoverOnOk(onOk ? () => onOk : undefined);
        setPopoverOnCancel(onCancel ? () => onCancel : undefined);
        setPopoverOpen(true);
    }

    const saveComp = () => {
        // if saving show spinner
        if (isSaving) {
            return <CircularProgress color='secondary' />;
        } else {
            // if just finished saving, show success indicator, otherwise 'Save' text
            return showSuccess ? 
                (saveSuccess ? <Done className={style.successIcon}/> : <Warning color='error'/>)
                :
                'Save'
        }
    }

    const _viewMetrics = () => {
        props.history.push({
                pathname: '/TrackerMetrics',
                state: props.location.state,
            });
    }
    const viewMetrics = () => {
        if (isStateDirty) {
            openPopover(
                "Discard unsaved changes?",
                _viewMetrics,
                () => void 0 // nothing to do
            );
        } else {
            _viewMetrics();
        }
    }

    // when finished saving, show the success indicator for a few seconds
    // also reload the system info
    useEffect(() => {
        // if saving flag was turned off, and save has been called at least once
        if (!isSaving && saveCalled.current) {
            setShowSuccess(true);
            setTimeout(
                () => {
                    setShowSuccess(false);
                    if (!saveSuccess) setSaveSuccess(true);
                },
                3 * 1000 // 3 seconds
            );
        }
    }, [isSaving])
    
    // Store Tracker type received from list view, and load tracker Filters
    useEffect(() => {
        const t = _.get(props, 'location.state.data');
        setTracker(t);
        loadFilters(t);
        setName(_.get(t, 'name', ''));
        setDescription(_.get(t, "description", ''));
        setTrackerTemplateId(_.get(t, "trackerTemplateId", ''));
        if (typeof t.private === 'boolean') setIsPrivate(t.private);
        if (typeof t.active === 'boolean') setActive(t.active);
    }, [props.location]);

    return (<div>
        {!_.isEmpty(tracker) &&
            <Paper className={style.root} elevation={2}>
                <Box display="flex" className={style.Header}>
                    <Button color="inherit" onClick={handleCancelClick}>
                        <ArrowBack />
                    </Button>
                    <Input
                        className={style.Title}
                        value={name}
                        onChange={(e) => setName(e.target.value ?? '')}
                        startAdornment={_.isEmpty(name) ? 
                            <div className={style.nameHelper}>Enter Name:</div> 
                            :
                            null
                        }
                        disableUnderline
                        autoFocus={!_.isEmpty(tracker) && _.isEmpty(name)}
                    />
                    <Button color="inherit" onClick={deleteTracker}>
                        Delete
                    </Button>
                    <Button 
                        color="inherit"
                        onClick={saveTracker}
                        disabled={!isStateDirty}
                    >
                        {saveComp()}
                    </Button>
                </Box>

                <div className={style.metricsButtonContainer}>
                    <Button
                        onClick={viewMetrics}
                        color='primary'
                        className={style.metricsButton}
                    >
                        View Metrics
                    </Button>
                </div>

                <form className={style.Form}>
                    <Box color='secondary' className={style.rightPadding}>
                        <TextField 
                            variant="outlined"
                            fullWidth={true}
                            className={style.FormInput}
                            classes={{root: style.mainColor}}
                            id="description"
                            label="Description"
                            multiline
                            maxRows={4}
                            value={description}
                            color='secondary'
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Box>
                    <Box display="flex" color='secondary' className={style.FormInput}>
                        <Select
                            color='secondary'
                            fullWidth={true}
                            labelId="demo-simple-select-label"
                            id="tracker_template_id"
                            value={trackerTemplateId}
                            displayEmpty
                            onChange={genericHandler(setTrackerTemplateId)}
                            className={style.rightMargin}
                        >
                            <MenuItem color='secondary' value="">
                                <em>No Template</em>
                            </MenuItem>

                            {_.map(trackerTemplates, template => <MenuItem
                                color='secondary'
                                key={template.id}
                                value={template.id}>
                                {template.name}
                            </MenuItem>)}

                        </Select>
                        <FormControlLabel
                            className={style.rightMargin}
                            control={
                                <Switch
                                    checked={active}
                                    onChange={(e, checked) => setActive(checked)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPrivate}
                                    onChange={(e, checked) => setIsPrivate(checked)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Private"
                        />
                    </Box>

                </form>

                <GoalFiltersSection
                    tracker={tracker}
                    orgLevels={orgLevels.current}
                    attributes={goalAttributes}
                    setAttributes={setGoalAttributes}
                    excludeAttributes={excludeGoalAttributes}
                    setExcludeAttributes={setExcludeGoalAttributes}
                    orgs={goalOrgs}
                    setOrgs={setGoalOrgs}
                    goalPeriod={goalPeriod}
                    setGoalPeriod={setGoalPeriod}
                />

                {/*
                <ProductFiltersSection
                    tracker={tracker}
                    phLevels={phLevels.current}
                    attributes={productAttributes}
                    setAttributes={setProductAttributes}
                    excludeAttributes={excludeProductAttributes}
                    setExcludeAttributes={setExcludeProductAttributes}
                    phs={phs}
                    setPhs={setPhs}
                    excludePhs={excludePhs}
                    setExcludePhs={setExcludePhs}
                />

                <AccountFiltersSection
                    tracker={tracker}
                    orgLevels={orgLevels.current}
                    attributes={accountAttributes}
                    setAttributes={setAccountAttributes}
                    excludeAttributes={excludeAccountAttributes}
                    setExcludeAttributes={setExcludeAccountAttributes}
                    orgs={orgs}
                    setOrgs={setOrgs}
                    excludeOrgs={excludeOrgs}
                    setExcludeOrgs={setExcludeOrgs}
                />
                */}

                <InfoPopover
                    message={popoverMessage}
                    onOk={popoverOnOk}
                    onCancel={popoverOnCancel}
                />

                <SysInfo trackerId={tracker.id} />

            </Paper>
        }
    </div>

    )
}

export default TrackerDetailPage;

/** MUI STYLE */
const trackerDetailPageStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: 1048,
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: 10,
    },
    Title: {
        flexGrow: 1,
        color: 'white',
        fontSize: 30,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        cursor: 'text',
        marginLeft: 5,
    },
    mainColor: {
        color: '#455A64'
    },
    Header: {
        // position: 'fixed',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        padding: "10px",
        height: '60px',
    },
    metricsButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    metricsButton: {
        height: '40px',
    },
    Form: {
        flexGrow: 1,
        margin: theme.spacing(1),
        padding: "10px",
    },
    FormInput: {
        margin: "10px 10px 20px 10px",
        color: '#455A64'
    },
    nameHelper: {
        color: '#ffabce',
        marginRight: 10
    },
    popover: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popoverPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    },
    popoverButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    popoverCancel: {
        marginRight: 10,
    },
    popoverClose: {
        marginLeft: 10,
    },
    popoverMessage: {
        display: 'flex',
        textAlign: 'center',
        padding: 20,
        fontSize: 20
    },
    successIcon: {
        color: 'green'
    },
    rightMargin: {
        marginRight: 40
    },
    rightPadding: {
        paddingRight: 30
    }
}));


