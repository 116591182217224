/********************************************************
 * File: AppBar.tsx
 * Project: @liquid-perform/mc
 * File Created: 08-19-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 *
 * Copyright © 2021 Liquid Analytics
 *********************************************************/

import React, { useState } from "react";
// firebase
import Cookie, {parse, serialize} from 'cookie';
import { getAuth, signOut } from "@firebase/auth";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppBar, Button, IconButton, Menu, Theme, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { AppBarMenuActions, ContextActionId, AppBarProfileMenuActions } from "../../common/constants";
import { AppStyledMenuItem } from "../core/Styled.Menu";
import { TypeAppBarContextualActions } from "../../typings/component.types";
import TooltipWrapper from "../core/Tooltip.Wrapper";
import { AppTheme } from "../../Theme";
import { AccountCircle } from "@material-ui/icons";

/** Constants */
const drawerWidth = 240;
const compact = 72;
const barDesktopHeight = 64;
 
/**
* AppBar Component
* @param props
*/
const AppBarComp = (props: any) => {
  const auth = getAuth();

  /////////////////////
  // STATE and HOOKs
  /////////////////////
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

   /////////////////////
  // CONSTANTS
  /////////////////////
  const style = appBarStyles(AppTheme);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const xsMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const {barTitle, ...appbarprops} = props;

  /////////////////////
  // EVENT HANDLERS
  /////////////////////
  const menuCloseHandler = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    setAnchorEl(null);
  };

  const mobileMenuCloseHandler = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    setMobileMoreAnchorEl(null);
  };


  const menuItemSelectHandler = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    action: TypeAppBarContextualActions,
    id?: string
  ) => {
    switch (action.id) {
      case ContextActionId.LOGOUT:
        signOut(auth);
	document.cookie = "access_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        location.reload();
        break;
      
      default:
        break;
    }

    menuCloseHandler();
    mobileMenuItemCloseHandler(event, action);
  };

  const mobileMenuItemCloseHandler = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, action: TypeAppBarContextualActions) => {
    mobileMenuCloseHandler();
    appButtonClicked(event, action);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const appButtonClicked = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>, actionInfo: TypeAppBarContextualActions) => {
    event.stopPropagation();

    switch (actionInfo.id) {  
      case ContextActionId.PROFILE:
        handleProfileMenuOpen(event);    
      default:
        break;
    }
  };

  ///////////////////////
  // AppBar Actions
  //////////////////////

  const profileMenu = (action: TypeAppBarContextualActions, checkDisabled = false) => {
    return (
      <Button
        key={`menu-button-${action.id}`}
        onClick={(event) => appButtonClicked(event, action)}
        style={{ backgroundColor: 'transparent' }}
        disabled={checkDisabled}
      >
        <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
          <TooltipWrapper key={action.id} title={action.label ?? ""}>
            <AccountCircle className={style.mainColor} fontSize='large'/>
          </TooltipWrapper>
        </div>
      </Button>
    );
  };

  //** common appbar actions */
  const appbarActions = (
    AppBarProfileMenuActions && AppBarProfileMenuActions.map((action, i) => {
        switch (action.id) {
          case ContextActionId.PROFILE:
            return profileMenu(action);
          default:
            return (
              <TooltipWrapper key={action.id} title={action.label ?? ""}>
                <IconButton
                  key={action.id}
                  onClick={(event) => appButtonClicked(event, action)}
                >
                  <AccountCircle />
                </IconButton>
              </TooltipWrapper>
            )
        }

      })
  );
 


  ///////////////////////
  // AppBar Profile Actions
  //////////////////////
  const AppBarProfileActions = (
    // (appContext.currentPage && appStateStore.appBarState.contextualActions) ?
        AppBarMenuActions && AppBarMenuActions.map((action, i) => {
        switch (action.id) {
          default:
            return (
              <AppStyledMenuItem
                key={`profile-${action.id}-${i}`}
                onClick={(e: any) => menuItemSelectHandler(e, action)}
                label={action.label}
                Icon={action.Icon}
              />
            )
        }
      })
    //   : null
  );
    
  ///////////////////////
  // Overflow Actions
  //////////////////////
  /** common actions */
  const defaultOverflowActions = (
    AppBarMenuActions.map((action, i) => {
      const Icon = action.Icon;
      return (
        <AppStyledMenuItem
          key={`common-${action.id}-${i}`}
          label={action.label}
          Icon={Icon}
          onClick={(e: any) => menuItemSelectHandler(e, action)}
        />
      )
    })
  );

  ///////////////////////
  // Profile Menu Element
  ///////////////////////
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="appbar-profile-element"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={menuCloseHandler}
      classes={{ list: style.noPad }}
    >
      {AppBarProfileActions}
    </Menu>
  );

   ///////////////////////
  // Overflow Element
  //////////////////////
  const menuId = "appbar-overflow-element";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}

      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={menuCloseHandler}
    >
      {/* {overflowActions}*/}
      {defaultOverflowActions} 
      <hr />
     
    </Menu>
  );

   ///////////////////////////
  // Mobile Overflow Element
  //////////////////////////
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="appbar-overflow-element-mobile"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={mobileMenuCloseHandler}
    >
      {/* {overflowActions} */}
      {defaultOverflowActions}
      <hr />
      
    </Menu>
  );

 
   ///////////////////////
   // FC Element
   //////////////////////
  return (
    <div className={style.root}>
      <AppBar position="fixed" className={style.appBar} >
        <Toolbar className={style.toolbar}>
          <Typography color="secondary" variant="h5" noWrap>
            {barTitle}
          </Typography>
          {/** appbar actions */}
          {appbarActions}
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      {xsMatch && renderMobileMenu}
      {xsMatch && renderMenu}
    </div>
  );
};
 
/** MUI STYLES */
const appBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    mainColor: {
      color: '#455A64'
    },
    appBar: {
      display: 'flex',
      justifyContent: 'space-between',
      width: `100%`,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#FFF",
      color: "#000",
    },
    toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    },
    noPad: {
    padding: 0
  }
  })
);
 
export default AppBarComp;