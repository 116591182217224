import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, IconButton} from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';

type TypePaginateProps = {
  children?: React.ReactNode;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  totalItems: React.MutableRefObject<number>;
}

export function Paginate({offset, setOffset, pageSize, setPageSize, totalItems}: TypePaginateProps) {
  const style = styles();
  const numPages = Math.ceil(totalItems.current / pageSize);
  const currPage = (offset / pageSize) + 1;

  return (
    <Paper key={'paper-root-paginate-tracker'} className={style.paperRoot}>
      {/** Pagination Prefix */}
      <Typography 
        color="secondary"
        key={'typography-paginate-prefix-tracker'}
        className={clsx(style.cprefix, style.labelProminent)}
        variant="caption"
      >
        {`Total: ${totalItems.current}`}
      </Typography>
      {/** Navigation */}
      <div key={'container-paginate-tracker'} className={style.paginationContainer}>
        <IconButton
          className={clsx(style.paginateControl, style.paddingRight0)}
          disabled={offset === 0}
          onClick={() => setOffset(0)}
        >
          <FirstPage />
        </IconButton>
        <IconButton
          className={clsx(style.paginateControl, style.paddingLeft5)}
          disabled={offset === 0}
          onClick={() => setOffset(offset - pageSize)}
        >
          <ChevronLeft />
        </IconButton>
        <div className={style.paginationInfo}>
          <Typography color="secondary" className={clsx(style.paginationLabel, style.paginateInput)}>
            {`Page ${currPage} of ${numPages}`}
          </Typography>
        </div>
        <IconButton 
          className={clsx(style.paginateControl, style.paddingRight0)}
          disabled={(totalItems.current - offset) <= pageSize}
          onClick={() => setOffset(offset + pageSize)}
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          className={clsx(style.paginateControl, style.paddingLeft5)}
          disabled={(totalItems.current - offset) <= pageSize}
          onClick={() => setOffset(Math.floor(totalItems.current/pageSize) * pageSize)}
        >
          <LastPage />
        </IconButton>
      </div>
      {/** Pagination Suffix */}
      <div 
        key={'container-paginate-suffix-tracker'}
        className={style.perPageContainer}
      >
        <Typography
          color="secondary"
          className={clsx(style.csuffix, style.labelProminent)} 
          variant="caption"
        >
          Rows Per Page:
        </Typography>
        <NativeSelect
            className={style.perPageSelector}
            variant="outlined"
            value={pageSize}
            onChange={(e) => {
              const val = +e.target.value;
              if (typeof val === 'number' && !isNaN(val)) {
                setPageSize(val);
              }
            }}
        >
            {[10, 20, 30, 50].map((item, index) => (
              <option key={index} value={item}>{item}</option>
            ))}
        </NativeSelect>
      </div>
    </Paper>
  );
}

const styles = makeStyles((theme: any) => ({
  paperRoot: {
    color: "#455A64",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& > *': {
      outline: 'none',
    },
    borderTop: '1px solid #E3E3E3',
    borderBottom: '1px solid #E3E3E3',
    boxShadow: 'none',
  },
  labelProminent: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    margin: theme.spacing(1),
      
  },
  cprefix: {
    color: '#455a64',
    flexGrow: 1,
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontWeight: 'normal',
    fontSize: '14px'
  },
  csuffix: {
    color: '#455a64',
    flexShrink: 0,
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontWeight: 'normal',
    fontSize: '14px'
  },
  paginationContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  paginationInfo: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      fontSize: '14px' 
    },
  },
  paginateInput: {
    flexGrow: 1,
    fontSize: '14px',
    padding: '0px 2px'
  },
  paginationLabel: {
    alignSelf: 'center',
    flexShrink: 0,
    fontSize: '14px'
  },
  paginateControl: {
    color: '#607D8B',
    '&:disabled': {
      color: '#B0BEC5',
    },
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  paddingLeft5: {
    paddingLeft: '5px',
  },
  paddingRight0: {
    paddingRight: '0px',
  },
  perPageContainer: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  perPageSelector: {
    outline: 'none',
    height: '28px',
    width: '59px',
    fontSize: '14px',
    "& .MuiSelect-outlined": {
      padding: '5px 10px',
      backgroundColor: '#ffffff'
    }
  },
  grow: {
    flexGrow: 1,
  },

}));