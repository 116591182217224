/********************************************************
 * File: TrackerList.tsx
 * Project: @liquid-mc/ui
 * File Created: 08-31-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState, useRef, useEffect } from 'react';
import { TypeTracker } from '../../typings/tracker.types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppTheme } from '../../Theme';
import { 
  Button, Divider, Grid, Radio, RadioGroup, FormControlLabel,
  Paper, Typography, ButtonGroup, Icon, InputBase, FormControl, Fab
} from '@material-ui/core';
import {Add, Edit, Lock, Search } from '@material-ui/icons';
import { Paginate } from '../core/Paginate';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Unsubscribe, DocumentData } from 'firebase/firestore';
import { paginatedFetchData } from '../../api/firestoreFetch';
import { useFormatEmail } from '../../common/utils';
import { debounce } from 'lodash';

type TypeOrderBy = 'LAST_MODIFIED' | 'NAME' | 'CREATED_BY';
const trackerOrderFieldMap = {
  LAST_MODIFIED: 'updatedAt',
  NAME: 'name',
  CREATED_BY: 'createdBy'
}

type TypeRenderRowProps = {
  children?: React.ReactNode;
  tracker: TypeTracker;
  idx: number;
  onRowClick?: (tracker: TypeTracker) => void;
  onEditClick?: (tracker: TypeTracker) => void;
}

// renders a tracker list item
function renderRow({ tracker, idx, onRowClick, onEditClick, ...props }: TypeRenderRowProps) {
  const style = styles(AppTheme);
  return (
    <div>
      <div className={style.rowContainer}>
        {/* main row button going to metrics / reports page */}
        <Button
          onClick={() => onRowClick && onRowClick(tracker)}
          key={`${tracker.id}`}
          fullWidth
          className={style.rowButton}
        >
          {/* start components */}
          <div className={style.rowStart}>
            {/* lock icon if private */}
            <div className={style.lockContainer}>
              {tracker.private &&
                <Icon className={style.lockIcon}>
                  <Lock />
                </Icon>
              }
            </div>
            {/* Name and description column */}
            <Grid container direction='column' className={style.leftMargin}>
              {/* Name */}
              <Typography 
                color="secondary"
                variant="h6"
                className={style.regText}
              >
                {tracker.name ?? tracker.id}
              </Typography>
              {/* Description */}
              <div className={clsx(style.descriptionContainer, style.regText)} >
                  {tracker.description ?? ''}
              </div>
            </Grid>
          </div>

          {/* end components */}
          <div className={style.rowEnd}>
            {/* created by */}
            <Typography
              color="secondary"
              variant='subtitle2'
              className={clsx(style.regText, style.rowEndText)}
              noWrap
            >
              {tracker.createdBy && tracker.createdBy}
            </Typography>
            {/* updated at */}
            <Typography 
              color="secondary"
              variant='subtitle2'
              className={clsx(style.regText, style.rowEndText)}
              noWrap
            >
              {tracker.updatedAt && tracker.updatedAt.toDateString()}
            </Typography>
          </div>
        </Button>

        {/* edit button to go to edit details page */}
        <Button 
          onClick={() => onEditClick && onEditClick(tracker)}
          key={`${tracker.id}-edit`}
          className={style.flexCenter}
        >
          <Grid item className={style.editContainer}>
            <Icon className={style.lockIcon}>
              <Edit />
            </Icon>
          </Grid>
        </Button>
      </div>
      <Divider light />
    </div>
  );
}

type TypeTrackerHeaderProps = {
  children?: React.ReactNode;
  orderByVal: TypeOrderBy;
  setOrderByVal: React.Dispatch<React.SetStateAction<TypeOrderBy>>;
  ascending: boolean;
  setAscending: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
}

function TrackerHeader({orderByVal, setOrderByVal, ascending, setAscending, setSearchVal}: TypeTrackerHeaderProps) {
  const style = styles(AppTheme);

  // debounced func only fires after specified ms
  const setSearchValDebounced = debounce(setSearchVal, 300);

  const orderButton = (id: TypeOrderBy) => (
    <Button 
      variant={orderByVal === id ? 'contained' : 'outlined'} 
      color='primary'
      onClick={() => !(orderByVal === id) && setOrderByVal(id)}
    >
      {id.replaceAll('_', ' ')}
    </Button>
  );

  const styledRadio = (
    <Radio 
      color='primary'
      className={style.radioRoot}
      icon={<span className={style.radioIcon} />}
      checkedIcon={<span className={style.checkedRadioIcon} />}
    />
  );

  return (
    <div className={style.headerContainer}>
      <Grid container direction='row' wrap='nowrap' justifyContent='space-between' className={style.headerGridContainer}>
        
        {/* container for left side of header */}
        <Grid container item direction='row' wrap='nowrap' justifyContent='flex-start'>
          {/* order by */}
          <Grid container item direction='column' justifyContent='flex-end' className={style.marginBottom}>
            <Grid container item>
              <Typography color="secondary" variant='body2' className={style.regText}>
                Order By:
              </Typography>
            </Grid>
            <Grid container item>
              <ButtonGroup aria-label="order by" disableElevation size='small'>
                {orderButton('NAME')}
                {orderButton('LAST_MODIFIED')}
                {orderButton('CREATED_BY')}
              </ButtonGroup>
            </Grid>
          </Grid>

          {/* order by direction (asc or desc) */}
          <Grid container item direction='column' justifyContent='flex-end'>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label="sort direction"
                name="direction"
                value={ascending}
                onChange={(e) => {
                  // make sure the value is boolean
                  const val = e.target.value;
                  if (val === 'true' || val === 'false') {
                    setAscending(val === 'true' ? true : false);
                  }
                }}
              >
                <FormControlLabel 
                  value={true}
                  control={styledRadio}
                  label={<Typography variant="body2" color="secondary">Ascending</Typography>}
                />
                <FormControlLabel
                  value={false}
                  control={styledRadio}
                  label={<Typography variant="body2" color="secondary">Descending</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* search */}
        <Grid container item direction='row' alignItems='flex-end' justifyContent='flex-end' className={style.marginBottom}>
          <div className={style.search}>
            <div className={style.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: style.inputRoot,
                input: style.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setSearchValDebounced(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}




type TypeTrackerListProps = {
  children?: React.ReactNode;
  onRowClick: (tracker: TypeTracker) => void;
  onEditClick: (tracker: TypeTracker) => void;
  onAddClick: () => void;
}

// renders the virtualized tracker list
export function TrackerList({ onRowClick, onEditClick, onAddClick}: TypeTrackerListProps) {
  const style = styles(AppTheme);

  // State for paginated data fetching
  const [trackers, setTrackers] = useState<TypeTracker[]>([]);
  const [orderByVal, setOrderByVal] = useState<TypeOrderBy>('LAST_MODIFIED');
  const [ascending, setAscending] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [pageSize, setPageSize] = useState(10);
  // need both offsets to compare new one with old to figure out pagination
  // see fetchData()
  const [offset, setOffset] = useState(0); // offset updated by the pagination component
  // paginated list state
  const totalTrackers = useRef(-1);
  const currOffset = useRef(0); // offset currently shown in the list
  const firstDoc = useRef<TypeTracker>();
  const lastDoc = useRef<TypeTracker>();
  const loading = useRef(false);
  const unsubscribe = useRef<Unsubscribe>(() => void 0);
  const parseTrackerData = (t: DocumentData) => {
    // get date and convert to JS Date
    let ua = t.get('updatedAt') ?? null;
    if (ua != null) ua = ua.toDate();
  
    // get other fields in the return
    return ({
      id: t.id,
      name: t.get('name'),
      description: t.get('description'),
      updatedAt: ua,
      createdBy: t.get('createdBy'),
      private: t.get('private', false),
      goalPresets: t.get('goalPresets'),
      trackerTemplateId: t.get('trackerTemplateId', '')
    } as TypeTracker);
  }

  ////////////
  // HOOKS
  ////////////
  
  // fetch trackers asynchronously on load and when order or search values change
  useEffect(() => {
    paginatedFetchData({
      collectionName: 'Tracker',
      countDoc: 'Aggregate/tracker',
      setItems: setTrackers,
      loading: loading,
      unsubscribe: unsubscribe,
      pageSize: pageSize,
      parseItem: parseTrackerData,
      offset: offset,
      orderByVal: trackerOrderFieldMap[orderByVal] ?? '',
      ascending: ascending,
      searchVal: searchVal,
      totalItems: totalTrackers,
      currOffset: currOffset,
      firstDoc: firstDoc,
      lastDoc: lastDoc,
    })

    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribe.current();
    }
  }, [ascending, searchVal, offset, pageSize]);

  // hook for specifically orderBy (reset page / offset when changed)
  useEffect(() => {
    // reset offset to zero
    setOffset(0);
    paginatedFetchData({
      collectionName: 'Tracker',
      countDoc: 'Aggregate/tracker',
      setItems: setTrackers,
      loading: loading,
      unsubscribe: unsubscribe,
      pageSize: pageSize,
      parseItem: parseTrackerData,
      offset: offset,
      orderByVal: trackerOrderFieldMap[orderByVal] ?? '',
      ascending: ascending,
      searchVal: searchVal,
      totalItems: totalTrackers,
      currOffset: currOffset,
      firstDoc: firstDoc,
      lastDoc: lastDoc,
    })
  }, [orderByVal]);

  return (
    <div className={style.root}>
      <div className={style.listContainer}>
        <TrackerHeader 
          orderByVal={orderByVal}
          setOrderByVal={setOrderByVal}
          ascending={ascending}
          setAscending={setAscending}
          setSearchVal={setSearchVal}
        />
        <Fab color='primary' size='medium' onClick={onAddClick} className={style.fab}>
          <Add/>
        </Fab>
        <AutoSizer disableWidth>
          {({ height, width }) => (
            <Paper
              style={{height: height}}
              className={style.listPaper}
              variant={'outlined'}
            >
              <FixedSizeList
                height={height}
                width={'100%'}
                itemSize={rowHeight}
                itemCount={trackers.length}
                className={style.list}
                itemKey={(index, data) => {
                  if (data && data[index] && data[index].id) return `${data[index].id}-${index}`
                  else return `tracker-${index}`
                }}
              >
                {(props) => renderRow({
                  tracker: trackers[props.index],
                  idx: props.index,
                  onRowClick: onRowClick,
                  onEditClick: onEditClick
                })}
              </FixedSizeList>
              <Paginate
                offset={offset}
                setOffset={setOffset}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalItems={totalTrackers}
              />
            </Paper>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

const rowHeight = 80;
const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center'
  },
  listPaper: {
    width: '100%',
    borderWidth: 1.5,
  },
  list: {
    overflow: 'hidden'
  },

  marginBottom: {
    marginBottom: 7
  },

  fab: {
    position: 'fixed',
    right: 260,
    bottom: 50,
    zIndex: 3,
    color: 'primary',
    cursor: 'pointer'
  },

  // radio buttons!
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginLeft: 20
  },
  radioIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedRadioIcon: {
    borderRadius: '50%',
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },

  pageSizeFormControl: {
    minWidth: 75,
  },
  pageSizeContainer: {
    display: 'flex',
    width: '46%',
    marginRight: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end',
    height: 80,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    paddingLeft: 0,
    paddingRight: 2,
    paddingBottom: 0
  },
  headerGridContainer: {
    alignContent: 'center',
  },


  listContainer: {
    width: '80%',
    height: '100%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: rowHeight,
  },
  rowButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowStart: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 60,
  },
  descriptionContainer: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'grey',
    lineHeight: '1.2em',
    marginTop: 5
  },
  rowEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowEndText: {
    marginLeft: 10,
    marginRight: 10,
  },
  regText: {
    textTransform: 'none',
    textAlign: 'start',
  },
  lockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    marginLeft: 20,
  },
  lockIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    color: '#616161'
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // header search bar
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#e3e3e3',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'background-color 0.2s',
    marginTop: theme.spacing(1),
    marginLeft: 0,
    width: '40%',
    height: 40,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    color: '#455A64',
    paddingLeft: theme.spacing(1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: '#455A64',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  leftMargin: {
    marginLeft: 25,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100
  }
}));