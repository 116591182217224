/********************************************************
 * File: GoalFiltersSection.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-08-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState, useEffect, useRef } from 'react';
import { map as loMap, get as loGet, last as loLast, assign as loAssign } from 'lodash';
import { TypeTracker } from '../../typings/tracker.types';
import { FilterSection, TypeFilterConfig } from '../core/FilterSection';
import { GridColDef } from '@material-ui/data-grid';
import {
  getFirestore, collection, onSnapshot, DocumentData, query, where, getDocs
} from 'firebase/firestore';
import { SelectorGrid } from '../core/ItemSelectorGrid';
import HierarchicalSelectorMenu from '../../components/core/HierarchicalSelectorMenu';

// Goal selector grid columns
const goalCols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, type: 'number' },
  { field: 'name', headerName: 'Name', width: 160 },
  { field: 'status', headerName: 'Status', width: 160, sortable: false },
];

const parseGoalData = (t: DocumentData) => {
  // get date and convert to JS Date
  let ua = t.get('updatedAt') ?? null;
  if (ua != null) ua = ua.toDate();

  // get other fields in the return
  return ({
    id: t.get('id') ?? t.id,
    name: t.get('name') ?? '',
    status: t.get('status') ?? '',
    explicit: t.get('isExplicit') ?? false
  });
}

type TypeGoalFiltersSectionProps = {
  tracker: TypeTracker;
  orgLevels: {[key: number]: any};
  attributes: any[];
  excludeAttributes: any[];
  setExcludeAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  setAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  orgs: any[];
  setOrgs: React.Dispatch<React.SetStateAction<any[]>>;
  goalPeriod: any[];
  setGoalPeriod: React.Dispatch<React.SetStateAction<any[]>>;
}

function GoalFiltersSection({
  tracker,
  orgLevels,
  attributes,
  setAttributes,
  excludeAttributes,
  setExcludeAttributes,
  orgs,
  setOrgs,
  goalPeriod,
  setGoalPeriod,
}: TypeGoalFiltersSectionProps) {
  const db = getFirestore();

  //////////////////////
  // HIERARCHY SELECTORS
  //////////////////////

  /////////
  // attributes selector
  const [allAttributes, setAllAttributes] = useState<any[]>([]);
  const loadingAttributes = useRef(false);
  const delegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    loadingAttributes.current = true;
    if (selectedPath.length === 0) {
      // Nothing has been drilled, show initial list
      loadingAttributes.current = false;
      return loMap(allAttributes, (entry, index) => ({
        index: index,
        value: entry.id,
        display: entry.display,
        key: entry.key,
        chevron: true
      }));
    }

    // One item has been drilled, so show the enums for it.
    const selectedIndex = loGet(loLast(selectedPath), 'index')
    loadingAttributes.current = false;
    return loMap(loGet(allAttributes, `${selectedIndex}.enums`), entry => ({
      value: entry.value,
      display: entry.display,
      chevron: false
    }));
  }

  // load goal attributes on attach
  useEffect(() => {
    loadingAttributes.current = true;
    return onSnapshot(
      query(collection(db, "Attribute"), where("collection", "==", "Goal")),
      snap => {
        loadingAttributes.current = false;
        setAllAttributes(loMap(snap.docs, doc => loAssign(doc.data(), { id: doc.id })))
      }
    );
  }, []);

  const transformSelection = (selectedPath: any[], selectedOption: any) => ({
    key: selectedPath[0]?.key ?? '',
    value: selectedOption.value ?? '',
    display: selectedOption.display ?? selectedOption.value,
    scope: selectedPath[0]?.display ?? '',
    label: selectedOption.display ?? selectedOption.value ?? '',
  })

  const goalAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={attributes}
      setSelected={setAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  /////////
  // exclude attributes selector
  // reuse include delegate, loaded data

  const excludeGoalAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={excludeAttributes}
      setSelected={setExcludeAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  //////////
  // org selector
  const loadingOrgs = useRef(false);
  const parseOrgDoc = (doc: DocumentData) => {
    // replace the level number with the display name
    const lvl = doc.get('level') ?? '';
    let key = lvl;
    let scope = lvl;
    if (typeof lvl === 'number' && Object.keys(orgLevels).length > 0) {
      key = orgLevels[lvl].display ?? orgLevels[lvl].value ?? '';
      scope = key;
    }
    return {
      value: doc.id,
      key: key,
      display: doc.get('name') ?? '',
      scope: scope,
      label: `${doc.get('name') ?? ''}: ${doc.id}`
    };
  }

  // map parent IDs => array of children
  // NOTE: the parentId for level 0 will be 'ROOT' even though there isn't a parent
  type Cache = {
    [parentId: string]: any[]
  }

  const orgsCache = useRef<Cache>({'ROOT': []});
  const orgsCacheInitialized = useRef(false);

  const orgDelegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    loadingOrgs.current = true;
    const lvl = selectedPath.length + 1;
    const parentId = lvl === 1 ? 'ROOT' : loLast(selectedPath).value;

    // check if its cached
    if (orgsCache.current.hasOwnProperty(parentId)) {
      loadingOrgs.current = false;
      return orgsCache.current[parentId].map((c: any, idx: number) => { return {
        ...c,
        index: idx,
        chevron: true 
      }});
    } else { // fetch if not cached
      const childrenQuery = query(collection(db, 'Org'), where('parentOrgId', '==', parentId));
      const orgs = await getDocs(childrenQuery);
      orgsCache.current[parentId] = [];
      orgs.docs.forEach(doc => {
        const org = parseOrgDoc(doc);
        orgsCache.current[parentId].push(org); // add org to cache!
      });
      loadingOrgs.current = false;
      return orgsCache.current[parentId].map((c: any, idx: number) => { return {
        ...c,
        index: idx,
        chevron: lvl >= Object.keys(orgLevels).length ? false : true 
      }});
    }
  }

  // load root org(s) on attach (just level 1)
  useEffect(() => {
    // get level 1 orgs
    if (!orgsCacheInitialized.current) {
      orgsCacheInitialized.current = true;
      return onSnapshot(
        query(collection(db, "Org"), where("level", "==", 1)),
        snap => {
          snap.docs.forEach(doc => {
            const org = parseOrgDoc(doc);
            orgsCache.current['ROOT'].push(org); // add org to cache!
          });
        }
      );
    }
  }, []);

  const orgSelector = (
    <HierarchicalSelectorMenu
      delegate={orgDelegate}
      selected={orgs}
      setSelected={setOrgs}
      loading={loadingOrgs}
      isMultiSelect
      hierarchical
    />
  );

  ////////////////
  // FILTER CONGIG
  ////////////////

  const goalFilters: TypeFilterConfig[] = [
    {
        name: 'Goal Attributes',
        selected: attributes,
        setSelected: setAttributes,
        hierarchySelector: goalAttributeSelector,
        chipColorGroup: 'goal'
    },
    {
      name: 'Exclude Goal Attributes',
      selected: excludeAttributes,
      setSelected: setExcludeAttributes,
      hierarchySelector: excludeGoalAttributeSelector,
      chipColorGroup: 'goal'
    },
    {
      name: 'Orgs',
      selected: orgs,
      setSelected: setOrgs,
      hierarchySelector: orgSelector,
      chipColorGroup: 'goal'
    },
    {
      name: 'Goal Period',
      selected: goalPeriod,
      setSelected: setGoalPeriod,
      dateSelector: true
    }
  ];

  return (
    <FilterSection
      title='Goal Filters'
      filters={goalFilters}
      gridSelector={(
        <SelectorGrid
          trackerId={tracker.id ?? ''}
          itemType='Goal'
          cols={goalCols}
          parseItemData={parseGoalData}
        />
      )}
    />
  )
}

export default GoalFiltersSection;