import  React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import AppBarComp from "./components/navigation/AppBar";
import { Route, Switch, useLocation} from "react-router-dom";
import { CompItems } from "./common/constants";
import ListPage from "./components/pages/TrackerListPage";
import TrackerDetailPage from "./components/pages/TrackerDetailPage";
import TrackerMetricsPage from "./components/pages/TrackerMetricsPage";
import { initializeApp, getApps } from "firebase/app";
import LoginPage from './components/core/Login';
import { AppTheme } from './Theme';

const App: React.FC = () => {

    // CONSTANTS
    const style = appStyles(AppTheme);
    const location = useLocation();

    const appConfig = {    
      apiKey: "AIzaSyB54umiVzk8Qg2Fi-RiuKC-M951vW9DzzI",
      authDomain: "ascend2021-323117.firebaseapp.com",
      projectId: "ascend2021-323117",
      storageBucket: "ascend2021-323117.appspot.com",
      messagingSenderId: "1019622864958",
      appId: "1:1019622864958:web:67ae42745d11c78c97b96f",
      measurementId: "G-NFFM937N2S"        
    };
           
    // Initialize the app using firebase      
    if (getApps.length === 0) {
      console.log('initializing FB');
      initializeApp(appConfig);
    }

    ///////////////////////
    // Local State
    //////////////////////
    const [currentPath, setCurrentPath] = useState('/');
    const [barTitle, setBarTitle] = useState('Tag Manager');

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    /////////////////////
    // METHODS
    ////////////////////

    // Get the path to navigate to corresponding component
    const fetchComponent = (path: string) => {
        switch(path){
            case CompItems.List.PATH:
                return ListPage;
            case CompItems.TrackerDetail.PATH:
              return TrackerDetailPage;
            case CompItems.TrackerMetrics.PATH:
                return TrackerMetricsPage;
            default:
                return ListPage;
        };
    }

     // Get the path to navigate to corresponding component
     const getBarTitle = (path: string) => {
        switch(path){
            case CompItems.List.PATH:
                setBarTitle(CompItems.List.TITLE);
                break;
            case CompItems.TrackerMetrics.PATH:
                setBarTitle(CompItems.TrackerMetrics.TITLE);
                break;
            default:
                setBarTitle(CompItems.List.TITLE);
                break;
        };
    }

    // HOOKS
    /** Handle menu routing in the app */
    useEffect(() => {
        setCurrentPath(location.pathname);
        getBarTitle(location.pathname);
    }, [location]);

    // ELEMENT
    return isLoggedIn
      ?
        <div className={style.root}>
          <AppBarComp barTitle={barTitle}/>
          <main className={style.content}>
            <div className={style.toolbar} />
            <Switch>
              <Route
                exact path={currentPath}
                component={fetchComponent(currentPath)}
              />
            </Switch>
          </main>
        </div>
      :
	<LoginPage setIsLoggedIn={setIsLoggedIn} />
};

const appStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      color: '#455A64'
    },
    fallbackContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10vh'
    },
    fallback: {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingTop: '5vh',
      paddingBottom: '5vh',
      width: '100vh',
      marginBottom: '5vh'
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      width: '100vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.between("sm", "md")]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

export default App; 
